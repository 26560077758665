import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";
import { format } from "date-fns";

import { getGridLocalTextTheme, getGridTheme, verifyRequestError } from "../../utils/utils";
import palette from "../../../config/colorPalette";
import TextModal from "../../components/TextModal";
import EditToolbar from "../../components/EditToolbar";

import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { getColumns, handleDeleteAsset } from "./utils/utils";

const Structures = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const dataTimeout = useRef();

  const currentConfig = useSelector((state) => state.configData);
  const currentUser = useSelector((state) => state.userData);
  const currentMenu = useSelector((state) => state.menuData);

  const userData = useSelector((state) => state.userData.data);
  const assets = useSelector((state) => state.assetsData);
  const localeData = useSelector((state) => state.configData.locale);

  const gridLocalTextTheme = useMemo(() => getGridLocalTextTheme(localeData), [localeData]);
  const gridTheme = useMemo(() => getGridTheme(), []);

  const [loading, setLoading] = useState(true);
  const [openTextModal, setOpenTextModal] = useState(false);
  const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
  const [currentAsset, setCurrentAsset] = useState(null);

  const columns = useMemo(() => {
    let result = [];
    if (!isNil(assets.data) && !isNil(userData)) {
      setLoading(false);
      result = getColumns(intl);
      const centroidColumn = {
        field: "centroid",
        headerName: "Centroid",
        width: 500,
        disableExport: true,
        renderCell: ({ id }) => {
          const asset = assets.data.find((equip) => equip.id === id);
          const latlon = asset.centroid;

          return (
            <>
              {decimalToDMS(latlon[0])}, {decimalToDMS(latlon[1])}
            </>
          );
        },
      };

      const actionsColumn = {
        field: "actions",
        type: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        width: 100,
        renderCell: ({ id }) => (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className="textPrimary"
            disabled={id === userData.id}
            onClick={() => {
              setCurrentAsset(assets.data.find((item) => item.id === id));
              setOpenTextModal(true);
            }}
            color="inherit"
          />
        ),
      };

      // Reemplazamos la última columna con las dos nuevas columnas
      if (userData?.role === "admin") {
        result.push(centroidColumn, actionsColumn);
      } else {
        result.push(centroidColumn);
      }
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, assets, userData]);

  useEffect(() => {
    return () => {
      clearInterval(dataTimeout.current);
      dataTimeout.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function decimalToDMS(decimal) {
    var degrees = Math.floor(decimal);
    var minutes = Math.floor((decimal - degrees) * 60);
    var seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);

    // Asegurar que los segundos tengan dos dígitos
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return degrees + "° " + minutes + "' " + seconds + '"';
  }

  useEffect(() => {
    if (!isNil(currentUser?.error)) {
      verifyRequestError({ error: currentUser.error, dispatch, navigate, intl });
    }
    if (!isNil(currentConfig?.error)) {
      verifyRequestError({ error: currentConfig.error, dispatch, navigate, intl });
    }
    if (!isNil(currentMenu?.error)) {
      verifyRequestError({ error: currentMenu.error, dispatch, navigate, intl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.error, currentConfig?.error, currentMenu?.error]);

  if (isNil(assets) || loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }
  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>{intl.formatMessage({ id: "assets" })}</div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(assets) || isEmpty(assets) ? 400 : "100%",
          width: "100%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
        }}
      >
        <DataGrid
          rows={assets.data?.map((item) => ({ ...item, customValue: item.value }))}
          columns={columns}
          columnVisibilityModel={{ id: false, type: false, value: false }}
          loading={loading}
          localeText={gridLocalTextTheme.components.MuiDataGrid.defaultProps.localeText}
          pagination
          disableRowSelectionOnClick
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar:
              userData?.role === "admin" || userData?.role === "super manager"
                ? {
                    btnText: intl.formatMessage({ id: "add asset" }),
                    btnIcon: <AddIcon />,
                    handleClick: () => navigate("/add_structure"),
                    excelExportOptions: {
                      data: { rows: assets, columns: columns },
                      filename: `${format(new Date(), "yyyyMMdd")}_${intl.formatMessage({
                        id: "assets",
                      })}_${intl.formatMessage({ id: "project.name" })}`,
                    },
                  }
                : {},
          }}
          sx={gridTheme}
        />
      </Box>
      <TextModal
        open={openTextModal}
        setOpen={setOpenTextModal}
        title={`${intl.formatMessage({ id: "delete asset" })}: "${currentAsset?.name}"`}
        text={intl.formatMessage({ id: "delete.asset.text" })}
        acceptFunction={() =>
          handleDeleteAsset({
            id: currentAsset?.id,
            assets,
            setLoading,
            setOpenTextModal,
            userData,
            dispatch,
            navigate,
            intl,
          })
        }
      />
      <TextModal
        open={openDeleteAllModal}
        setOpen={setOpenDeleteAllModal}
        title={intl.formatMessage({ id: "delete all assets" })}
        text={intl.formatMessage({ id: "delete.all.assets.text" })}
        acceptFunction={() =>
          handleDeleteAsset({
            id: null,
            assets,
            setLoading,
            setOpenTextModal: setOpenDeleteAllModal,
            dispatch,
            navigate,
            intl,
          })
        }
      />
    </div>
  );
};

export default Structures;
