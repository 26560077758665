export const UNAUTHORIZED_USER = "Unauthorized user";

export const BOOLEAN_MEASURES = ["leak", "landslide"];
export const OK = 0;
export const LOW = 1;
export const INTERMEDIATE = 2;
export const HIGH = 3;
export const ALERT_COLORS = [
  "rgb(0 255 68 / 56%)",
  "rgb(255 255 0 / 56%)",
  "rgb(255 165 0 / 56%)",
  "rgb(255 0 45 / 45%)",
];
