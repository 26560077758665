import React, { useEffect, useState } from "react";
import { LegendContainer } from "./styled";
import { isEmpty, isNil } from "ramda";
import { scaleLinear } from "d3";

const Legend = ({
  assets,
  assetsValues,
  selectedEquipment,
  selectedChannels,
  visible,
  assetEquipments,
  inside,
  filtered_asset,
  measure_filter,
  scales,
  colors = ["green", "yellow", "orange", "red"],
  intl,
}) => {
  const originalPositions = ["10%", "95%"];
  const [colorPos, setColorPos] = useState(["10%", "90%"]);
  const [positions, setPositions] = useState(originalPositions);
  const [thresholdTexts, setThresholdTexts] = useState(["ok", "low", "intermediate", "high"]);
  const [colorSteps, setColorSteps] = useState(colorPos.length);
  const stepSize = 1 / colorSteps;
  const measureScale = measure_filter !== "status" && measure_filter !== "status.secondary" ? measure_filter : "leak";
  const scaleNew = scaleLinear().domain(scales[measureScale].domain()).range(scales[measureScale].range());
  const legendItems = Array.from({ length: colorSteps }, (_, index) => {
    const value = index * stepSize;
    const color = scaleNew(value);
    return { value, color };
  });

  useEffect(() => {
    let bounds = ["ok", "low", "intermediate", "high"];
    let positionsLetters = originalPositions;
    let positionsColors = colorPos;
    if (
      !isNil(assets) &&
      measure_filter !== "status" &&
      measure_filter !== "status.secondary" &&
      filtered_asset !== "" &&
      !isNil(filtered_asset)
    ) {
      const asset = assets.find((asset) => asset.name === filtered_asset);
      const indexEquipment = asset.equipments.findIndex((item) => item.equipmentID === selectedEquipment);
      const equipment = asset.equipments[indexEquipment];
      if (!isNil(asset) && !isNil(equipment) && !isNil(assetsValues.data[asset.name])) {
        positionsLetters = ["10%"];
        const measure_index = equipment.pMeasureNames?.indexOf(measure_filter);
        const threshold = equipment.pThresholds?.[measure_index];
        const allEquipments = [];
        for (let index = 0; index < assetEquipments.length; index++) {
          for (let index2 = 0; index2 < assetEquipments[index].length; index2++) {
            allEquipments.push(assetEquipments[index][index2]);
          }
        }
        const realIndexEquipment = allEquipments.findIndex((element) => element.equipmentID === selectedEquipment);

        const data = assetsValues.data[asset.name][selectedEquipment];
        const maxPercentiles = [];
        const minPercentiles = [];
        //for (let index = 0; index < selectedChannels.length; index++) {
        if (
          (isEmpty(selectedChannels[realIndexEquipment]) || isNil(selectedChannels[realIndexEquipment])) &&
          visible[realIndexEquipment]
        ) {
          for (let index = 0; index < Object.keys(data).length; index++) {
            const channelData = data[(index + 1).toString()];
            if (!isEmpty(channelData) && !isNil(channelData.boundaries[measure_filter])) {
              maxPercentiles.push(channelData.boundaries[measure_filter][1]);
              minPercentiles.push(channelData.boundaries[measure_filter][0]);
            }
          }
        } else if (visible[realIndexEquipment]) {
          for (let indexChannel = 0; indexChannel < selectedChannels[realIndexEquipment].length; indexChannel++) {
            const id = (parseInt(selectedChannels[realIndexEquipment][indexChannel]) + 1).toString();
            const channelData = data[id];
            if (!isEmpty(channelData) && !isNil(channelData.boundaries[measure_filter])) {
              maxPercentiles.push(channelData.boundaries[measure_filter][1]);
              minPercentiles.push(channelData.boundaries[measure_filter][0]);
            }
          }
        }
        //}

        const max =
          Math.max(...maxPercentiles) === Infinity || Math.max(...maxPercentiles) === -Infinity
            ? 1
            : Math.max(...maxPercentiles);
        const min =
          Math.min(...minPercentiles) === -Infinity || Math.min(...minPercentiles) === Infinity
            ? 0
            : Math.min(...minPercentiles);
        const diff = max - min;
        if (diff <= 3) {
          bounds =
            measure_filter === "leak" || measure_filter === "landslide"
              ? ["alert"]
              : [
                  Math.round(min * 10) / 10,
                  Math.round((min + diff / 4) * 10) / 10,
                  Math.round((min + diff / 2) * 10) / 10,
                  Math.round((max - diff / 4) * 10) / 10,
                  Math.round(max * 10) / 10,
                ];
        } else {
          bounds = [
            Math.round(min),
            Math.round(min + diff / 4),
            Math.round(min + diff / 2),
            Math.round(max - diff / 4),
            Math.round(max),
          ];
        }
        if (threshold.length < 3 && (measure_filter === "leak" || measure_filter === "landslide")) {
          bounds.splice(0, threshold.length === 2 ? 1 : 2);
        }
        if (threshold[threshold.length - 1] <= 1 && (measure_filter === "leak" || measure_filter === "landslide")) {
          bounds.unshift("OK");
          bounds.push("Alert");
          positionsLetters.push("95%");
        }

        if (measure_filter === "leak" || measure_filter === "landslide") {
          positionsColors = ["10%", "95%"];
        } else if (measure_filter === "temperature" || measure_filter === "strain") {
          positionsLetters.push("32%");
          positionsLetters.push("52%");
          positionsLetters.push("72%");
          positionsLetters.push("92%");
          positionsColors = ["0%", "20%", "30%", "40%", "50%", "70%", "80%", "90%"];
        }
        setColorSteps(positionsColors.length);
      }
    } else if (!isNil(assets) && measure_filter === "status") {
      bounds = ["ok", "alert"];
      positionsColors = ["10%", "90%"];
      positionsLetters = ["10%", "95%"];
      setColorSteps(positionsColors.length);
    } else if (!isNil(assets) && measure_filter === "status.secondary") {
      positionsColors = ["0%", "30%", "40%", "50%", "70%", "90%"];
      positionsLetters = ["10%", "50%", "73%", "95%"];
      setColorSteps(positionsColors.length);
    }
    setThresholdTexts(bounds);
    setPositions(positionsLetters);
    setColorPos(positionsColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, filtered_asset, measure_filter, selectedEquipment, selectedChannels]);

  function isFloat(value) {
    return !isNaN(value) && value.toString().indexOf(".") !== -1;
  }

  return (
    <LegendContainer>
      <svg width="90%" height={"11.8rem"}>
        <defs>
          <linearGradient id="mapGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            {legendItems.map((item, index) => (
              <stop offset={colorPos[index]} stopColor={item.color} />
            ))}
          </linearGradient>
        </defs>
        <rect fill="url(#mapGradient)" x="10" y="10" rx="20" ry="20" width="30%" height={"18vh"} />
        {positions.map((p, i) => {
          return (
            <text x="45%" y={p} alignmentBaseline="start" textAnchor="start" fill="black">
              {Number.isInteger(thresholdTexts[i]) || isFloat(thresholdTexts[i])
                ? thresholdTexts[i]
                : intl.formatMessage({ id: thresholdTexts[i] })}
            </text>
          );
        })}
      </svg>
    </LegendContainer>
  );
};

export default Legend;
