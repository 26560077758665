import shared from "./shared";

const locales = {
  es: {
    ...shared.es,
  },
  en: {
    ...shared.en,
  },
};
export default locales;

export const languagesItems = [
  { value: "es", label: "Español" },
  { value: "en", label: "English" },
];
