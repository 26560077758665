import { isNil } from "ramda";

import { deleteRole, getUserRoleData } from "../../../api";
import { verifyRequestError } from "../../../utils/utils";

export const loadRoles = async (setRoles, setLoading, dispatch, navigate, intl) => {
  setLoading(true);
  try {
    const response = await getUserRoleData();
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      await setRoles(response.data.data);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
};

export const getColumns = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", width: 180, hide: true },
    { field: "type", headerName: intl.formatMessage({ id: "type" }), width: 120 },
    { field: "order", headerName: intl.formatMessage({ id: "order" }), width: 100, type: "number" },
    { field: "description", headerName: intl.formatMessage({ id: "description" }), width: 400, flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "actions" }),
      width: 100,
      cellClassName: "actions",
      getActions: null,
    },
  ];
  return columns;
};

export const handleDeleteRole = async ({ id, setRoles, setLoading, setOpenTextModal, dispatch, navigate, intl }) => {
  if (isNil(id)) {
    return;
  }
  try {
    const response = await deleteRole(id, intl);
    if (!isNil(response)) {
      loadRoles(setRoles, setLoading, intl);
      setOpenTextModal(false);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
};
