import styled from "styled-components";

export const LayoutContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const LayoutBodyContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const LayoutMenuContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.2rem;
  border-radius: 0 0.3rem 0.3rem 0;
  position: relative;
`;

export const LayoutWorkspaceContainer = styled.div`
  flex: 1;
  display: flex;
  height: 92.5vh;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 0.3rem;
`;

export const MenuOpenButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -16px;
  border-radius: 0 1rem 1rem 0;
  z-index: 1000;
`;

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 0.5rem;
  padding: ${({ open }) => (open ? "0.5rem 1rem" : "0.5rem 0.5rem")};

  &:hover {
    background: ${({ hovercolor }) => hovercolor ?? "white"} !important;
  }
`;

export const LayoutBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const OutletContainer = styled.div`
  height: 80vh;
  width: 100%;
`;
