import { isNil } from "ramda";
import { verifyRequestError } from "../../../utils/utils";

export const verifyData = ({ data, action, dispatch, navigate, intl }) => {
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      verifyRequestError({ error: data.error, dispatch, navigate, intl });
    }
    if (isNil(data.data) && data.loading === false) {
      dispatch(action());
    }
  }
};
