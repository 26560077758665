import { login } from "../../../api";
import { setAuthAction } from "../../../redux/authSlice";
import { validateEmail, validatePassword, verifyRequestError } from "../../../utils/utils";

export const handleChanges = ({ who, value, setUsernameError, setUsername, setPasswordError, setPassword, intl }) => {
  if (who === "username") {
    const isValid = validateEmail(value);
    setUsernameError(!isValid ? "email.verification.error.message" : null);
    setUsername(value);
  }
  if (who === "password") {
    const isValid = validatePassword(value);
    setPasswordError(!isValid ? "password.verification.error.message" : null);
    setPassword(value);
  }
};

export const loginUser = async ({ username, password, remember = false, setLoading, navigate, dispatch, intl }) => {
  setLoading(true);
  try {
    const response = await login(username, password, remember);
    if (response) {
      dispatch(setAuthAction(response.id));
      navigate("/");
      return;
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
  dispatch(setAuthAction(null));
};
