import { clone, isNil } from "ramda";

export const handleMeasure = ({ value, measures, setMeasures, index, threshold, setThreshold, id, errors }) => {
  if (value.length < 3) {
    errors[id] = "3.characters";
  } else {
    delete errors[id];
  }
  let newMeasures = [...measures];
  let th = threshold;

  if (value !== "") {
    newMeasures[index][0] = value;
    setMeasures(newMeasures);
    if (isNil(th[index])) {
      th.push([null, null, null]);
      setThreshold(th);
    }
  }
};

export const handleBoundaries = (value, measures, setMeasures, index, index2) => {
  let newMeasures = [...measures];

  if ((index2 === 1 && value < measures[index][2]) || (index2 === 2 && value > measures[index][1])) {
    newMeasures[index][index2] = value;
    setMeasures(newMeasures);
  }
};

export const handleDifferences = (value, differences, setDifferences, index) => {
  let newDifferences = [...differences];
  newDifferences[index] = value;
  setDifferences(newDifferences);
};

export const handleThresholdNumber = ({
  value,
  id,
  index,
  measures,
  threshold,
  setThreshold,
  index2,
  errors,
  setErrors,
  e,
}) => {
  const min = parseFloat(e.target.min);
  const max = parseFloat(e.target.max);
  let newThreshold = [...threshold];
  let newErrors = errors;
  const boundaries = measures[index];
  if (boundaries[1] < value && value < boundaries[2]) {
    //check the treshold
    if (min <= value && value <= max) {
      delete errors[id];
      if (!isNil(newThreshold[index][index2])) {
        newThreshold[index][index2] = value;
      } else {
        newThreshold[index].push(value);
      }
      setThreshold(newThreshold);
    } else {
      newErrors[id] = "between.low.high";
      setErrors(newErrors);
    }
  } else {
    newErrors[id] = "number.between.boundaries";
    setErrors(newErrors);
  }
};
export const handleThresholdSelect = ({
  value,
  index,
  index2,
  setOptions,
  options,
  thresholdType,
  threshold,
  setThreshold,
}) => {
  const newOptions = [];
  let optionsDup = options;
  for (let i = 0; i < options[index].length; i++) {
    const op = options[index][i];
    if (i <= index2) {
      newOptions.push(op);
    } else {
      newOptions.push(options[index][index2].filter((item) => item !== value));
    }
  }
  optionsDup[index] = newOptions;
  setOptions(optionsDup);
  const indexOfValue = thresholdType?.[0]?.indexOf(value);

  setThreshold((prevThreshold) => {
    const newThreshold = [...prevThreshold];
    newThreshold[index] = [...newThreshold[index]];
    newThreshold[index][indexOfValue] = 0;
    return newThreshold;
  });
};

export const onDeleteHandler = ({
  index,
  thresholdsValues,
  setThresholdsValues,
  measures,
  setMeasures,
  measuresAlerts,
  setMeasuresAlerts,
}) => {
  if (index > 0) {
    const localThresholdsValues = [...thresholdsValues];
    localThresholdsValues.splice(index, 1);
    setThresholdsValues(localThresholdsValues);
    const localMeasures = [...measures];
    localMeasures.splice(index, 1);
    setMeasures(localMeasures);
    const localMeasuresAlerts = [...measuresAlerts];
    localMeasuresAlerts.splice(index, 1);
    setMeasuresAlerts(localMeasuresAlerts);
  }
};

export const manageMeasures = ({
  equipmentCollection,
  setEquipmentCollection,
  thresholdsValues,
  setThresholdsValues,
  measures,
  setMeasures,
  measuresComputed,
  setMeasuresComputed,
  measuresAlerts,
  setMeasuresAlerts,
  objectMeasures,
  zones,
  setZones,
  thresholdsRanges,
  setThresholdsRanges,
}) => {
  if (!equipmentCollection.includes("")) {
    const localThresholdsValues = clone(thresholdsValues);
    const localMeasuresAlerts = clone(measuresAlerts);
    const localMeasures = clone(measures);
    const localZones = clone(zones);
    const localThresholdsRanges = clone(thresholdsRanges);
    const localMeasuresComputed = clone(measuresComputed);
    //definición de medidas que calculan los equipos
    for (let index = 0; index < equipmentCollection.length; index++) {
      if (isNil(localMeasures[`equipment${index + 1}`])) {
        localMeasures[`equipment${index + 1}`] = [""];
        localMeasuresComputed[`equipment${index + 1}`] = [""];
        localMeasuresAlerts[`equipment${index + 1}`] = [0];
        localZones.push([]);
      }
    }
    //borrar los strings vacíos
    for (let index = 0; index < Object.keys(localMeasures).length; index++) {
      const equipment = objectMeasures.find((om) => om.name === equipmentCollection[index]);
      if (localMeasures[`equipment${index + 1}`] !== equipment.measures) {
        localMeasures[`equipment${index + 1}`] = equipment.measures;
        for (let indexMeasure = 0; indexMeasure < equipment.measures.length; indexMeasure++) {
          for (let indexMeasures = 0; indexMeasures < localMeasures[`equipment${index + 1}`].length; indexMeasures++) {
            if (isNil(localMeasuresAlerts[`equipment${index + 1}`][indexMeasures])) {
              localMeasuresAlerts[`equipment${index + 1}`].push(0);
            }
          }

          if (isNil(localThresholdsValues[`equipment${index + 1}`][indexMeasure])) {
            localThresholdsValues[`equipment${index + 1}`].push([[[{ type: null, value: 0 }]]]);
            localThresholdsRanges[`equipment${index + 1}`].push([[[0, 1]]]);
            localZones[index][indexMeasure] = [1];
          }
        }
      }
      if (localMeasuresComputed[`equipment${index + 1}`] !== equipment.computing && !isNil(equipment.computing)) {
        localMeasuresComputed[`equipment${index + 1}`] = equipment.computing;
      }
    }
    setMeasures(localMeasures);
    setThresholdsValues(localThresholdsValues);
    setMeasuresAlerts(localMeasuresAlerts);
    setMeasuresComputed(localMeasuresComputed);
    setThresholdsRanges(localThresholdsRanges);
    setZones(localZones);
  }
};
