import { isNil } from "ramda";

import { getAlertTypeNames, verifyRequestError } from "../../../utils/utils";
import {
  deleteAsset,
  deleteData,
  deleteDocument,
  deleteEquipment,
  getAssetsData,
  getDocuments,
  getEquipmentsByAsset,
} from "../../../api";

export const loadAssets = async (setAssets, setLoading, dispatch, navigate, intl) => {
  setLoading(true);
  try {
    const response = await getAssetsData();
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      await setAssets(response.data.data);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
};

export const getColumns = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", hide: true, disableExport: true },
    { field: "name", headerName: intl.formatMessage({ id: "name" }), width: 200, hide: true },
    { field: "dateTime", headerName: intl.formatMessage({ id: "date" }), width: 200, hide: true },
    { field: "zoom", headerName: "Zoom", width: 100 },
  ];
  return columns;
};

export const handleDeleteAsset = async ({ id, assets, setOpenTextModal, userData, dispatch, navigate, intl }) => {
  if (isNil(id)) {
    // delete all
    if (!isNil(assets)) {
      try {
        await assets.data.forEach(async (item) => {
          await deleteAsset(item.id, intl);
          const equipments = getEquipmentsByAsset(item.name);
          await deleteData(item.name, intl);

          await equipments.data.data.forEach(async (itemEquipment) => {
            if (itemEquipment.asset === item.name) {
              deleteEquipment(itemEquipment.id, intl);
            }
          });
          //let newUserData = clone(userData);
          //newUserData.assets.splice(newUserData.assets.indexOf(item.name), 1);
          //putUser(newUserData.id, newUserData);
        });
        setOpenTextModal(false);
      } catch (error) {
        throw Error("The asset was not deleted");
      }
    }
    return;
  }
  try {
    const data = assets.data.find((item) => item.id === id);
    await deleteData(data.name, intl);
    const equipments = await getEquipmentsByAsset(data.name);
    try {
      await equipments.data.data.forEach(async (itemEquipment) => {
        if (itemEquipment.asset === data.name) {
          deleteEquipment(itemEquipment.id, intl);
        }
      });
    } catch (error) {
      throw Error("The equipment was not deleted");
    }
    const documents = await getDocuments(data.name);
    try {
      await documents.data.data.forEach(async (itemDocument) => {
        if (itemDocument.asset === data.name) {
          deleteDocument(itemDocument, intl);
        }
      });
    } catch (error) {
      throw Error("The equipment was not deleted");
    }
    const response = await deleteAsset(id, intl);
    if (!isNil(response)) {
      setOpenTextModal(false);
    }
    //let newUserData = clone(userData);
    //newUserData.assets.splice(newUserData.assets.indexOf(data.name), 1);
    //putUser(newUserData.id, newUserData);
    window.location.reload();
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
};

export const getOptions = (intl) => {
  const allOptions = getAlertTypeNames(intl);
  const result = new Array(allOptions.length);
  for (let i = 0; i < result.length; i++) {
    result[i] = allOptions.map((op, index) => index);
  }
  return result;
};
