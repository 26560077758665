import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isNil } from "ramda";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, IconButton, Tooltip } from "@mui/material";

import { logout } from "../../../../api/auth";
import { getImageSrc } from "../../../../utils/utils";
import uptechOnly from "../../../../../images/Uptech-only.png";
import avatar from "../../../../../images/avatar.png";
import { setAuthAction } from "../../../../redux/authSlice";
import { HeaderContainer, HeaderLogo } from "./styled";
import UserProfileModal from "../UserProfileModal";

const Header = ({ backgroundColor, color = "black", user }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstTime, setFirstTime] = useState(false);

  const [openProfileModal, setOpenProfileModal] = useState(false);

  useEffect(() => {
    if (!isNil(user) && user.firstTime) {
      setOpenProfileModal(true);
      setFirstTime(true);
    }
  }, [firstTime, user]);

  return (
    <>
      <HeaderContainer style={{ backgroundColor }} data-testid="header">
        <HeaderLogo alt="uptech" src={uptechOnly} />
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <div data-testid="header-profile-btn" onClick={() => setOpenProfileModal(true)}>
            <Tooltip title={intl.formatMessage({ id: "edit profile" })}>
              <Avatar
                alt="avatar"
                src={user?.avatar ? getImageSrc(user.avatar) : avatar}
                sx={{ width: "6vh", height: "6vh", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
          <div>
            <div>{user?.role}</div>
            <div>{user?.name}</div>
          </div>
          <Tooltip title={intl.formatMessage({ id: "logout" })}>
            <IconButton
              data-testid="header-logout-btn"
              size="large"
              color="inherit"
              onClick={() => {
                logout();
                dispatch(setAuthAction(null));
                navigate("/login");
              }}
            >
              <LogoutIcon style={{ color }} />
            </IconButton>
          </Tooltip>
        </div>
      </HeaderContainer>
      {openProfileModal && (
        <UserProfileModal
          firstTime={firstTime}
          setFirstTime={setFirstTime}
          open={openProfileModal}
          setOpen={setOpenProfileModal}
          user={user}
        />
      )}
    </>
  );
};

export default Header;
