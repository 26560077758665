import React from "react";
import { isNil } from "ramda";

import { utils as utilsXLSX, writeFileXLSX } from "xlsx";

import { createTheme } from "@mui/material/styles";
import { enUS, esES } from "@mui/x-data-grid";
import { enUS as coreEnUS, esES as coreEsES } from "@mui/material/locale";
import { darken, lighten } from "@mui/material/styles";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import GppMaybeTwoToneIcon from "@mui/icons-material/GppMaybeTwoTone";
import { gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid";

import palette from "../../config/colorPalette";
import { logout } from "../api/auth";
import { setAuthAction } from "../redux/authSlice";
import { UNAUTHORIZED_USER } from "./consts";
import { toast } from "react-toastify";

export const menuAccessVerification = (currentPageURL, menuData, navigate) => {
  if (!isNil(menuData)) {
    const option = menuData.find((item) => item.page === currentPageURL);
    if (isNil(option)) {
      navigate("/");
    } else {
      return true;
    }
  }
  return false;
};

export const getMuiTheme = () => {
  let result = createTheme({
    palette: {
      common: { black: palette.dark, white: palette.likeWhite },
      primary: { main: palette.primary, contrastText: "#fff" },
      secondary: { main: palette.secondary, contrastText: "#fff" },
      error: { main: "#d32f2f", contrastText: "#fff" },
      warning: { main: "#ed6c02", contrastText: "#fff" },
      info: { main: "#0288d1", contrastText: "#fff" },
      success: { main: "#2e7d32", contrastText: "#fff" },
      background: { paper: palette.likeWhite, default: palette.likeWhite },
      divider: palette.dividerLight,
    },
    typography: {
      fontFamily: '"fagoco", sans-serif',
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderColor: "#6c6c6a",
            borderRadius: "4px",
            "&.Mui-focused": {
              borderColor: "#6c6c6a !important",
            },
          },
          input: {
            borderColor: "#6c6c6a",
          },
          notchedOutline: {
            borderColor: "#6c6c6a",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "#6c6c6a",
            },
          },
        },
      },
      MuiChip: { styleOverrides: { deleteIcon: { color: palette.likeWhite } } },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: palette.primary,
              borderRadius: "25px",
            },
          },
        },
      },
    },
  });
  result.overrides = {
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#6c6c6a",
        },
      },
    },
  };
  return result;
};

export const getGridLocalTextTheme = (locale) => {
  switch (locale) {
    case "en":
      return createTheme({}, enUS, coreEnUS);
    case "es":
      return createTheme({}, esES, coreEsES);
    default:
      return createTheme({}, enUS, coreEnUS);
  }
};

export const getGridTheme = () => {
  let result = {
    "& .MuiDataGrid-menuIconButton": {
      color: palette.likeWhite,
    },
    "& .MuiDataGrid-sortIcon": {
      color: palette.likeWhite,
    },
    "& .MuiDataGrid-iconSeparator": {
      color: palette.likeWhite,
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: palette.dark,
      color: palette.likeWhite,
      fontSize: 16,
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        "&:nth-of-type(2n-1)": {
          backgroundColor: palette.likeWhite,
          "&:hover": {
            backgroundColor: darken(palette.dividerLight, 0.2),
          },
        },
        "&:nth-of-type(2n)": {
          backgroundColor: lighten(palette.dividerLight, 0.6),
          "&:hover": {
            backgroundColor: palette.dividerLight,
          },
        },
      },
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  };

  return result;
};

export const getImageSrc = (stringBase64) => {
  let src = null;
  if (!isNil(stringBase64)) {
    const bytes = atob(stringBase64);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < bytes.length; i++) {
      view[i] = bytes.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
    src = URL.createObjectURL(blob);
  }
  return src;
};

export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

export const validatePassword = (value) => {
  return value?.length > 7;
};

export const validateSecondPassword = (password, secondPassword) => {
  return validatePassword(password) && validatePassword(secondPassword) && password === secondPassword;
};

export const getAlertThemeData = (type, size, intl) => {
  switch (type) {
    case 0:
      return {
        title: intl.formatMessage({ id: "ok" }),
        backgroundColor: "green",
        icon: null,
      };
    case 1:
      return {
        title: intl.formatMessage({ id: "low" }),
        backgroundColor: "#fdfdc0",
        icon: <InfoTwoToneIcon fontSize={size} sx={{ color: "#cdcd00 !important" }} />,
      };
    case 2:
      return {
        title: intl.formatMessage({ id: "intermediate" }),
        backgroundColor: "#f8cf82",
        icon: <WarningTwoToneIcon fontSize={size} sx={{ color: "#c37e00 !important" }} />,
      };
    default:
      return {
        title: intl.formatMessage({ id: "high" }),
        backgroundColor: "#ffb1b1",
        icon: <GppMaybeTwoToneIcon fontSize={size} sx={{ color: "red !important" }} />,
      };
  }
};

export const getAlertTypeNames = (intl) => {
  return [
    intl.formatMessage({ id: "low" }),
    intl.formatMessage({ id: "intermediate" }),
    intl.formatMessage({ id: "high" }),
  ];
};

export const getExportData = (data, columns, intl) => {
  const typeNames = getAlertTypeNames(intl);

  const exportData = data.map((item) => {
    const objetoModificado = {};
    Object.keys(item).forEach((key) => {
      const headerName = columns.find(
        (c) => (isNil(c.disableExport) || !c.disableExport) && c.field === key,
      )?.headerName;
      if (!isNil(headerName)) {
        objetoModificado[headerName] = key === "type" ? typeNames[item[key] - 1] : item[key];
      }
    });
    return objetoModificado;
  });

  return exportData;
};

export const exportToExcel = ({ data, sheetName, filename, apiRef, intl }) => {
  const filteredRowsId = gridFilteredSortedRowIdsSelector(apiRef);
  const values = getExportData(
    !isNil(data.rows) ? data.rows.filter((r) => filteredRowsId.includes(r.id)) : [],
    data.columns,
    intl,
  );
  const ws = utilsXLSX.json_to_sheet(values);
  const wb = utilsXLSX.book_new();
  utilsXLSX.book_append_sheet(wb, ws, sheetName);
  writeFileXLSX(wb, `${filename}.xlsx`);
};

export const verifyRequestError = ({ error, dispatch, navigate, intl }) => {
  let errorMessage = error?.message ?? error;
  if (error?.message === UNAUTHORIZED_USER || error?.response?.status === 401) {
    errorMessage = error.message ?? error.response.message;
    logout();
    dispatch(setAuthAction(null));
    navigate("/login");
  }
  toast.error(errorMessage, { className: "toast-error" });
};

export const handlePointClick = (latitude, longitude) => {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  window.open(googleMapsUrl, "_blank");
};
