import React from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import palette from "../../../config/colorPalette";
import { Content, LinkBox } from "./styled";

const NotFound = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Content data-testid="not-found">
      <span>{intl.formatMessage({ id: "not.found.page" })}</span>
      <LinkBox color={palette.primary} onClick={() => navigate("/")} data-testid="not-found-btn">
        {intl.formatMessage({ id: "go.home" })}
      </LinkBox>
    </Content>
  );
};

export default NotFound;
