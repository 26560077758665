import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RolePermissions from "../RolePermissions";
import { Divider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import palette from "../../../../../config/colorPalette";
import { getMuiTheme, verifyRequestError } from "../../../../utils/utils";
import { postRole, putRole } from "../../../../api";

const RoleModal = ({ open, setOpen, role, type, acceptFunction }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);

  const [localRole, setLocalRole] = useState(role);

  const readOnly = type !== "edit" && type !== "create";

  const handleClose = () => {
    setLocalRole(null);
    setOpen(false);
  };

  const handleRoleModalAccept = async (data, type) => {
    if (type === "view") {
      handleClose();
      return false;
    }
    try {
      let response = null;
      if (type === "create") {
        response = await postRole(data, intl);
      } else if (type === "edit") {
        response = await putRole(data.id, data, intl);
      }
      if (!isNil(response)) {
        return true;
      }
    } catch (error) {
      verifyRequestError({ error, dispatch, navigate, intl });
    }
    return false;
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle style={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>
            {`${intl.formatMessage({ id: type })} ${intl.formatMessage({ id: "role" })}`}
          </DialogTitle>
          <DialogContent style={{ paddingBottom: "0" }}>
            <div>
              <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
              <div style={{ display: "flex" }}>
                <div>
                  <div style={{ fontSize: "larger" }}>{intl.formatMessage({ id: "role information" })}</div>
                  <TextField
                    autoFocus
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="type"
                    label={intl.formatMessage({ id: "type" })}
                    type="text"
                    fullWidth
                    value={localRole?.type ?? ""}
                    onChange={(event) => setLocalRole({ ...localRole, type: event.target.value })}
                    disabled={readOnly}
                  />
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="order"
                    label={intl.formatMessage({ id: "order" })}
                    type="number"
                    fullWidth
                    value={localRole?.order ?? 0}
                    onChange={(event) => setLocalRole({ ...localRole, order: event.target.value })}
                    disabled={readOnly}
                  />
                  <TextField
                    margin="dense"
                    autoComplete="off"
                    variant="outlined"
                    id="description"
                    label={intl.formatMessage({ id: "description" })}
                    type="text"
                    fullWidth
                    value={localRole?.description ?? ""}
                    onChange={(event) => setLocalRole({ ...localRole, description: event.target.value })}
                    disabled={readOnly}
                  />
                </div>
                <div style={{ margin: "0 1rem", minWidth: "15rem" }}>
                  <div style={{ fontSize: "larger" }}>{intl.formatMessage({ id: "permissions selection" })}</div>
                  <RolePermissions
                    usedValues={localRole?.options ?? []}
                    setUsedValues={(values) => setLocalRole({ ...localRole, options: values })}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <Divider style={{ marginTop: "1rem" }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: palette.secondary, color: "white" }}
              onClick={handleClose}
            >
              {intl.formatMessage({ id: type === "view" ? "close" : "cancel" })}
            </Button>
            {(type === "edit" || type === "create") && (
              <Button
                variant="contained"
                style={{ backgroundColor: palette.primary, color: "white" }}
                onClick={async () => {
                  const result = await handleRoleModalAccept({ ...localRole, order: localRole.order ?? 0 }, type);
                  if (result) {
                    acceptFunction();
                    handleClose();
                  }
                }}
              >
                {intl.formatMessage({ id: "accept" })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default RoleModal;
