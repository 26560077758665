import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { clone, isNil, isEmpty } from "ramda";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DnsIcon from "@mui/icons-material/Dns";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Divider } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DescriptionIcon from "@mui/icons-material/Description";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { MenuContainer, MenuItemButton, MenuItemContainer, MenuOpenButton } from "../../styled";
import palette from "../../../../../config/colorPalette";
import { setSelectedMenuOptionAction } from "../../../../redux/menuSlice";
import { verifyRequestError } from "../../../../utils/utils";

const MENU_ICONS = {
  home: <LocationOnIcon />,
  alarms: <NotificationsActiveIcon />,
  monitorization: <ModeOfTravelIcon />,
  analysis: <QueryStatsIcon />,
  configuration: <SettingsIcon />,
  administration: <AdminPanelSettingsIcon />,
  structures: <ApartmentIcon />,
  users: <SupervisorAccountIcon />,
  roles: <SupervisedUserCircleIcon />,
  equipments: <DnsIcon />,
  documentation: <DescriptionIcon />,
  "data.downloads": <SimCardDownloadIcon />,
};

const Menu = ({ open, handleMenuOpen, color, dividerColor, backgroundColor }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentMenu = useSelector((state) => state.menuData);
  const userData = useSelector((state) => state.userData);

  const [openItems, setOpenItems] = useState([]);

  const menuItems = useMemo(() => {
    if (!isNil(currentMenu?.data) && !isNil(userData?.data)) {
      let options = clone(currentMenu.data);
      const used = [];
      const menu = [];
      options.forEach((item) => {
        if (!used.includes(item.id) && userData.data.options.includes(item.value)) {
          let flag = false;
          const result = {
            text: intl.formatMessage({ id: item.label }),
            icon: MENU_ICONS[item.label],
            value: item.value,
          };
          if (isEmpty(item.options)) {
            result["to"] = item.page;
            flag = true;
          } else {
            result["children"] = [];
            item.options.forEach((id) => {
              const opt = options.find((m) => m.id === id);
              used.push(id);
              if (!isNil(opt) && userData.data.options.includes(opt.value)) {
                if (opt.value === currentMenu.selectedOption) {
                  setOpenItems([...openItems, intl.formatMessage({ id: item.label })]);
                }
                result.children.push({
                  text: intl.formatMessage({ id: opt.label }),
                  icon: MENU_ICONS[opt.label],
                  to: opt.page,
                  value: opt.value,
                });
                flag = true;
              }
            });
          }
          if (flag) {
            menu.push(result);
          }
        }
      });
      menu.sort((a, b) => a.value - b.value);
      return menu;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu, userData, intl]);

  useEffect(() => {
    if (!isNil(userData?.error)) {
      verifyRequestError({ error: userData.error, dispatch, navigate, intl });
    }
    if (!isNil(currentMenu?.error)) {
      verifyRequestError({ error: currentMenu.error, dispatch, navigate, intl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.error, currentMenu?.error]);

  const getSubMenuItems = (item, level, open) => {
    const oItems = openItems.filter((i) => i !== item.text) ?? [];
    const isOpen = openItems.includes(item.text);
    return (
      <div key={`${item.text}-${level}`}>
        {open && (
          <MenuItemButton
            data-testid={`menu-item-${item.value}`}
            open={open}
            hovercolor={palette.primary}
            style={{ background: currentMenu?.selectedOption === item.value ? palette.primary : "inherit" }}
            onClick={() => {
              if (item.children) {
                setOpenItems(isOpen ? [oItems] : [...oItems, item.text]);
              } else if (!isNil(item.to)) {
                dispatch(setSelectedMenuOptionAction(item.value));
                navigate(item.to);
              }
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <div style={{ marginLeft: `${level * 1.5}rem`, display: "flex" }}>{item.icon}</div>
              {item.text}
            </div>
            {isOpen ? <ArrowDropDownIcon /> : item.children && <ArrowRightIcon />}
          </MenuItemButton>
        )}
        {!isNil(item.children) && isOpen && item.children.map((i) => getSubMenuItems(i, level + 1, open))}
      </div>
    );
  };

  return (
    <MenuContainer style={{ color }} data-testid="menu">
      <MenuOpenButton data-testid="menu-open-btn" onClick={handleMenuOpen} open={open} style={{ backgroundColor }}>
        {open ? (
          <KeyboardDoubleArrowLeftIcon style={{ height: "18px", width: "18px" }} />
        ) : (
          <KeyboardDoubleArrowRightIcon style={{ height: "18px", width: "18px" }} />
        )}
      </MenuOpenButton>
      {!isNil(menuItems) &&
        menuItems.map((item, index) => (
          <div key={`${item.text}-0`}>
            {index !== 0 && <Divider style={{ borderColor: dividerColor }} />}
            <MenuItemContainer>{getSubMenuItems(item, 0, open)}</MenuItemContainer>
          </div>
        ))}
    </MenuContainer>
  );
};

export default Menu;
