import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getAssetsData, getEquipments, getUserData } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};
const assetsSlice = createSlice({
  name: "ASSET",
  initialState,
  reducers: {
    getAssetsDataRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getAssetsDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAssetsDataFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAssetsDataRequest, getAssetsDataSuccess, getAssetsDataFailure } = assetsSlice.actions;

export const getAssetsDataAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getAssetsDataRequest());
      let response = await getAssetsData();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;
        let userResponse = await getUserData(id);
        if (userResponse.data.data.role !== "admin") {
          payload = payload.filter((a) => a.name !== "IBIZA-FORMENTERA");
        }
        response = await getEquipments();
        if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
          const equipments = response.data.data ?? [];
          payload = payload.map((a) => ({ ...a, equipments: equipments.filter((e) => e.asset === a.name) ?? [] }));
        }
        dispatch(getAssetsDataSuccess(payload));
        return;
      }
      dispatch(getAssetsDataFailure("Invalid response"));
    } catch (error) {
      dispatch(getAssetsDataFailure(error));
    }
  };
};

export const clearAssetsDataAction = () => {
  return async (dispatch) => {
    dispatch(getAssetsDataSuccess(null));
  };
};

export default assetsSlice.reducer;
