import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authSlice from "../code/redux/authSlice";
import configSlice from "../code/redux/configSlice";
import userSlice from "../code/redux/userSlice";
import assetsSlice from "../code/redux/assetsSlice";
import menuSlice from "../code/redux/menuSlice";
import assetsValuesSlice from "../code/redux/assetsValuesSlice";
import assetsPointsSlice from "../code/redux/assetsPointsSlice";
import equipmentTypeSlice from "../code/redux/equipmentTypeSlice";
import documentationSlice from "../code/redux/documentationSlice";

const store = configureStore({
  reducer: {
    authData: authSlice,
    configData: configSlice,
    userData: userSlice,
    assetsData: assetsSlice,
    assetsPoints: assetsPointsSlice,
    assetsValuesData: assetsValuesSlice,
    menuData: menuSlice,
    equipmentTypeData: equipmentTypeSlice,
    documentData: documentationSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
