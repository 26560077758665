import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import { Divider } from "@mui/material";
import { ToastContainer } from "react-toastify";

import Header from "./components/Header";
import Menu from "./components/Menu";

import palette from "../../../config/colorPalette";
import { getUserDataAction } from "../../redux/userSlice";
import { getAssetsDataAction } from "../../redux/assetsSlice";
import { getConfigDataAction } from "../../redux/configSlice";
import { getMenuDataAction, setSelectedMenuOptionAction } from "../../redux/menuSlice";
import { CopyrightContent, FooterContent } from "../../pages/Login/styled";
import { verifyData } from "./utils/utils";
import {
  LayoutBody,
  LayoutBodyContainer,
  LayoutContainer,
  LayoutMenuContainer,
  LayoutWorkspaceContainer,
  OutletContainer,
} from "./styled";
import "react-toastify/dist/ReactToastify.css";
import { getEquipmentTypesAction } from "../../redux/equipmentTypeSlice";
import { getDocumentsDataAction } from "../../redux/documentationSlice";

const Layout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authData);
  const currentUser = useSelector((state) => state.userData);
  const currentConfig = useSelector((state) => state.configData);
  const currentMenu = useSelector((state) => state.menuData);

  const dataTimeout = useRef();
  const assets = useSelector((state) => state.assetsData);

  const [isMenuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    verifyData({
      data: currentUser,
      action: () => getUserDataAction(authData.isAuthenticated),
      dispatch,
      navigate,
      intl,
    });
    verifyData({ data: currentMenu, action: getMenuDataAction, dispatch, navigate, intl });
    verifyData({ data: assets, action: () => getAssetsDataAction(authData.isAuthenticated), dispatch, navigate, intl });
    verifyData({ data: assets, action: getEquipmentTypesAction, dispatch, navigate, intl });
    verifyData({ data: assets, action: getDocumentsDataAction, dispatch, navigate, intl });

    return () => {
      clearInterval(dataTimeout.current);
      dataTimeout.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    verifyData({ data: currentConfig, action: getConfigDataAction, dispatch, navigate, intl });
    if (!isNil(currentConfig?.data?.loadTime) && !isNil(assets?.data)) {
      //verifyData({
      //  data: assetsValues,
      //  action: () => getAssetsValuesAction(assets.data),
      //  dispatch,
      //  navigate,
      //  intl,
      //});
      //verifyData({
      //  data: assetsPoints,
      //  action: () => getAssetsPointsAction(assets.data),
      //  dispatch,
      //  navigate,
      //  intl,
      //});
      //clearInterval(dataTimeout.current);
      //dataTimeout.current = setInterval(() => {
      //  dispatch(getAssetsValuesAction(assets.data));
      //  dispatch(getAssetsPointsAction(assets.data));
      //}, currentConfig.data.loadTime * 60 * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConfig, assets]);

  useEffect(() => {
    if (!isNil(currentMenu?.data)) {
      const currentUrl = window.location.href;
      let currentPage = currentUrl.split("/");
      currentPage = `/${currentPage[currentPage.length - 1]}`;
      const currentValue = currentMenu.data.find((item) => item.page === currentPage)?.value;
      if (!isNil(currentValue)) {
        dispatch(setSelectedMenuOptionAction(currentValue));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu]);

  return (
    <LayoutContainer data-testid="layout" style={{ backgroundColor: palette.dark }}>
      <Header backgroundColor={palette.likeWhite} color={palette.dark} user={currentUser?.data} />
      <Divider style={{ borderColor: palette.dark, border: "2px solid" }} />
      <LayoutBodyContainer>
        <LayoutMenuContainer open={isMenuOpen} style={{ backgroundColor: palette.dark }}>
          <Menu
            open={isMenuOpen}
            handleMenuOpen={() => setMenuOpen(!isMenuOpen)}
            color={palette.light}
            dividerColor={palette.dividerLight}
            backgroundColor={palette.dark}
          />
        </LayoutMenuContainer>
        <LayoutWorkspaceContainer data-testid="layout-workspace" open={isMenuOpen}>
          <LayoutBody style={{ backgroundColor: palette.likeWhite }}>
            <OutletContainer>
              <Outlet />
            </OutletContainer>
            <FooterContent>
              <CopyrightContent>{`Copyright © 2024 UPTECH. ${intl.formatMessage({
                id: "copyright",
              })}.`}</CopyrightContent>
            </FooterContent>
          </LayoutBody>
        </LayoutWorkspaceContainer>
      </LayoutBodyContainer>
      <ToastContainer limit={4} position="top-right" autoClose={5000} theme="dark" />
    </LayoutContainer>
  );
};

export default Layout;
