import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { setSelectedMenuOptionAction } from "../../redux/menuSlice";
import Map from "./components/Map";
import { menuAccessVerification } from "../../utils/utils";
import { loadAlerts } from "../Alarms/utils/utils";
import { isEmpty, isNil } from "ramda";

const Home = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assets = useSelector((state) => state.assetsData);
  const assetsValues = useSelector((state) => state.assetsValuesData);
  const menuData = useSelector((state) => state.menuData.data);
  const assetsPoints = useSelector((state) => state.assetsPoints);
  const configData = useSelector((state) => state.configData.data);
  const equipmentTypes = useSelector((state) => state.equipmentTypeData.data);
  const [objectMeasures, setObjectMeasures] = useState(null);
  useEffect(() => {
    if (!isNil(equipmentTypes)) {
      const newData = equipmentTypes.map((item) => {
        const measures = [];
        const computing = [];
        item.pMeasureNames.forEach((measure, index) => {
          if (measure !== "depth_of_burial") {
            if (item.pMeasureComputed[index] === 0) {
              measures.push(measure);
            } else {
              computing.push(measure);
            }
          }
        });

        const newEntry = { name: item.name, measures: measures };
        if (computing.length > 0) {
          newEntry.computing = computing;
        }
        return newEntry;
      });
      setObjectMeasures(newData);
    }
  }, [equipmentTypes]);

  const [alerts, setAlerts] = useState();
  const [loading, setLoading] = useState();
  const [mapColors, setMapColors] = useState();
  const [borders, setBorders] = useState();
  useEffect(() => {
    const assetsApp = {};
    if (!isNil(assets.data)) {
      for (let index = 0; index < assets.data.length; index++) {
        assetsApp[assets.data[index].name] = [];
      }
    }
    setBorders(assetsApp);
  }, [assets]);
  useEffect(() => {
    if (menuAccessVerification("/home", menuData, navigate)) {
      const i = menuData.find((m) => m.page === "/home").value;
      dispatch(setSelectedMenuOptionAction(i));
      loadAlerts({ setAlerts, setLoading, dispatch, navigate, intl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);
  useEffect(() => {
    if (!isNil(configData)) {
      setMapColors(configData.colorMaps);
    }
  }, [configData]);
  useEffect(() => {
    dispatch(setSelectedMenuOptionAction(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNil(alerts) || isNil(assets?.data) || assets.loading || loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }
  if (isEmpty(alerts?.data) || isEmpty(assets?.data)) {
    return (
      <div style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <h2>{`${intl.formatMessage({ id: "data.didnt.came" })}`}</h2>
      </div>
    );
  }
  return (
    <Map
      assets={assets.data}
      alerts={alerts}
      points={assetsPoints}
      borders={borders}
      setBorders={setBorders}
      assetsValues={assetsValues}
      mapColors={mapColors}
      objectMeasures={objectMeasures}
      dispatch={dispatch}
      intl={intl}
    />
  );
};

export default Home;
