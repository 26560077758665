import styled from "styled-components";

export const Content = styled.div`
  margin: 0px;
  display: flex;
  align-items: center;
  position: relative;
  background-image: ${({ image }) => (image ? `url(${image})` : "")};
  background-size: cover;
  background-repeat: no-repeat;
`;
export const LeftTextBox = styled.div`
  text-align: center;
  margin: 1rem;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

export const LeftContent = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  gap: 1rem;
  font-size: 4.5rem;
  color: white;

  @media (max-width: 1050px) {
    display: none;
  }
`;

export const RightContent = styled.div`
  height: 100vh;
  width: 55vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  @media (max-width: 1050px) {
    width: 100vw;
    background-color: transparent;
  }
`;

export const LogoContent = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;

  @media (max-width: 1050px) {
    height: 7vh;
  }
`;

export const LogoImg = styled.img`
  width: 8rem;
`;

export const FormContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 500px;
  min-width: 350px;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;

  @media (max-width: 1050px) {
    width: 350px;
  }
`;

export const RememberContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
`;

export const LinkBox = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.1rem;
  align-items: center;
  color: ${({ color }) => color ?? "#009cde"};

  span {
    color: ${({ color }) => color ?? "#009cde"} !important;
  }
`;

export const FooterContent = styled.div`
  padding: 1rem;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const UptechLogoImg = styled.img`
  width: 15rem;
  margin: 1rem;

  @media (max-width: 1050px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 8rem;
  }
`;

export const CopyrightContent = styled.div`
  font-size: 0.5rem;
  color: #5f5e5e;
  margin-top: 0.3rem;
`;

export const ErrorText = styled.span`
  margin: 2px 5px;
  font-size: 0.6rem;
  color: red;
`;

export const TextBox = styled.div`
  font-size: ${({ size }) => size ?? ""};
  color: ${({ color }) => color ?? "inherit"};
  width: 100%;
`;
