import React, { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Button, FormControl, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { manageMeasures } from "./utils";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { clone, isEmpty, isNil } from "ramda";
import { ErrorText } from "./styled";
import { getMuiTheme } from "../../../../utils/utils";
import { ThemeProvider } from "styled-components";
import palette from "../../../../../config/colorPalette";

const AssetInfoForm = ({
  assetName,
  setAssetName,
  zoom,
  setZoom,
  demarcation,
  setDemarcation,
  avatar,
  setAvatar,
  equipmentCollection,
  setEquipmentCollection,
  measures,
  setMeasures,
  measuresComputed,
  setMeasuresComputed,
  measuresAlerts,
  setMeasuresAlerts,
  thresholdsValues,
  setThresholdsValues,
  errors,
  setErrors,
  alertTimeRange,
  alertTimeMeasure,
  setAlertTimeRange,
  setAlertTimeMeasure,
  URL,
  setURL,
  zones,
  setZones,
  thresholdsRanges,
  setThresholdsRanges,
  objectMeasures,
  configData,
}) => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);
  const equipmentOptions = ["DTS", "DTSS", "DAS"];
  const prevEquipmentCollection = useRef(equipmentCollection);
  //const [computing, setComputings] = useState([]);

  useEffect(() => {
    if (assetName.length < 3) {
      setErrors((prevErrors) => ({ ...prevErrors, "asset-name": "3.characters" }));
    } else {
      setErrors((prevErrors) => {
        const { "asset-name": assetName, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  }, [assetName, setErrors]);

  useEffect(() => {
    if (prevEquipmentCollection.current !== equipmentCollection) {
      manageMeasures({
        equipmentCollection,
        setEquipmentCollection,
        thresholdsValues,
        setThresholdsValues,
        measures,
        setMeasures,
        measuresComputed,
        setMeasuresComputed,
        measuresAlerts,
        setMeasuresAlerts,
        objectMeasures,
        zones,
        setZones,
        thresholdsRanges,
        setThresholdsRanges,
      });
      prevEquipmentCollection.current = equipmentCollection;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentCollection]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={10} direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} sm={2}>
            <FormControl>
              <TextField
                autoFocus
                value={assetName}
                variant="outlined"
                autoComplete="off"
                margin="dense"
                id="asset-name"
                label={intl.formatMessage({ id: "name" })}
                type="text"
                fullWidth
                required
                error={errors["asset-name"] ? true : false}
                onChange={(e) => {
                  setAssetName(e.target.value);
                }}
              ></TextField>
              {!isNil(errors["asset-name"]) && (
                <ErrorText>{intl.formatMessage({ id: errors["asset-name"] })}</ErrorText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl>
              <TextField
                autoFocus
                value={URL}
                variant="outlined"
                autoComplete="off"
                margin="dense"
                id="url"
                label={intl.formatMessage({ id: "url-microservice" })}
                type="text"
                fullWidth
                required
                error={errors["url"] ? true : false}
                onChange={(e) => {
                  setURL(e.target.value);
                }}
              ></TextField>
              {!isNil(errors["url"]) && <ErrorText>{intl.formatMessage({ id: errors["url"] })}</ErrorText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              id="zoom"
              margin="dense"
              style={{ width: "5rem" }}
              variant="outlined"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              label={"Zoom"}
              required
              type="number"
              inputProps={{ min: 0, max: 16 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div style={{ marginTop: "10%" }}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="file-input"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file && file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      const base64String = reader.result;
                      setAvatar(base64String.split("base64,")?.[1]);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
              <label htmlFor="file-input">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: avatar.length === 0 ? palette.primary : "#420d10",
                    color: avatar.length > 0 && "grey",
                  }}
                  component="span"
                >
                  upload image {avatar.length > 0 && <CheckIcon />}
                </Button>
              </label>
            </div>
          </Grid>
          {!isNil(configData) && !isNil(configData.demarcations) && !isEmpty(configData.demarcations) ? (
            <Grid item xs={12} sm={2}>
              <TextField
                id="demarcation"
                margin="dense"
                style={{ width: "10rem" }}
                variant="outlined"
                value={demarcation}
                onChange={(e) => setDemarcation(e.target.value)}
                select
                label={intl.formatMessage({ id: "demarcation" })}
                required
              >
                {configData.demarcations.map((dem, demIndex) => {
                  return (
                    <MenuItem key={`dem_${dem}_${demIndex}`} value={dem}>
                      {dem}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={3}>
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", margin: "0.5rem" }}>
              <TextField
                id={"alert_time_range"}
                style={{ marginTop: "0.2rem" }}
                value={alertTimeRange}
                onChange={(e) => {
                  const newErrors = { ...errors };
                  if (isEmpty(e.target.value)) {
                    newErrors[e.target.id] = "not.empty";
                  } else {
                    delete newErrors[e.target.id];
                  }
                  setErrors(newErrors);
                  setAlertTimeRange(e.target.value);
                }}
                type="number"
                variant="outlined"
                error={errors["alert_time_range"] ? true : false}
                label={intl.formatMessage({ id: "alert.time.range" })}
                required
              ></TextField>
              {!isNil(errors["alert_time_range"]) && (
                <ErrorText>{intl.formatMessage({ id: errors["alert_time_range"] })}</ErrorText>
              )}
              <TextField
                id="alert_time_measure"
                margin="dense"
                style={{ width: "14rem" }}
                variant="outlined"
                value={alertTimeMeasure}
                defaultValue={1}
                error={errors["alert_time_measure"] ? true : false}
                onChange={(e) => {
                  setAlertTimeMeasure(parseInt(e.target.value));
                }}
                select
                label={intl.formatMessage({ id: "alert.time.measure" })}
                required
              >
                <MenuItem value={1}>{intl.formatMessage({ id: "hours" })}</MenuItem>
                <MenuItem value={24}>{intl.formatMessage({ id: "days" })}</MenuItem>
              </TextField>
            </div>
          </Grid>
        </Grid>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", padding: "0 0.7rem" }}>
          <div style={{ fontSize: "large", marginTop: "0.5rem", width: "7rem" }}>
            <div style={{ minWidth: "10rem", margin: "2rem" }}>{intl.formatMessage({ id: "equipments" })}:</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", margin: "0.5rem" }}>
            {equipmentCollection.map((et, index) => (
              <>
                <TextField
                  id={`type${index}`}
                  margin="dense"
                  style={{ width: "15rem" }}
                  variant="outlined"
                  value={et}
                  onChange={(e) => {
                    let newTypes = clone(equipmentCollection);

                    newTypes[index] = e.target.value;
                    setEquipmentCollection(newTypes);
                  }}
                  select
                  label={intl.formatMessage({ id: "select.type.equipment" })}
                  required
                >
                  {equipmentOptions.map((eo, index2) => (
                    <MenuItem value={eo}>{eo}</MenuItem>
                  ))}
                </TextField>
              </>
            ))}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {equipmentCollection.length < 2 ? (
              <IconButton
                style={{ backgroundColor: palette.dividerLight }}
                onClick={() => {
                  setEquipmentCollection((et) => [...et, ""]);
                  let thv = clone(thresholdsValues);
                  thv[`equipment${equipmentCollection.length + 1}`] = [[[[{ type: null, value: 0 }]]]];
                  setThresholdsValues(thv);
                  let newMeasures = clone(measures);
                  newMeasures[`equipment${equipmentCollection.length + 1}`] = [""];
                  setMeasures(newMeasures);
                  let newMeasuresAlerts = clone(measuresAlerts);
                  newMeasuresAlerts[`equipment${equipmentCollection.length + 1}`] = [0];
                  setMeasuresAlerts(newMeasuresAlerts);
                  let newThresholdRanges = clone(thresholdsRanges);
                  newThresholdRanges[`equipment${equipmentCollection.length + 1}`] = [[[[0, 1]]]];
                  setThresholdsRanges(newThresholdRanges);
                  let newZones = clone(zones);
                  newZones.push([[1]]);
                  setZones(newZones);
                }}
              >
                <AddIcon sx={{ color: palette.primary }} />
              </IconButton>
            ) : (
              <DeleteForeverIcon
                onClick={() => {
                  const newET = clone(equipmentCollection);
                  newET.splice(1, 1);
                  setEquipmentCollection(newET);
                  let newThresholds = clone(thresholdsValues);
                  let newMeasures = clone(measures);
                  let newMeasuresAlerts = clone(measuresAlerts);
                  let newZones = clone(zones);
                  let newThresholdRanges = clone(thresholdsRanges);
                  delete newThresholds[`equipment2`];
                  delete newThresholdRanges[`equipment2`];
                  delete newMeasures[`equipment2`];
                  delete newMeasuresAlerts[`equipment2`];
                  delete newZones[newZones.length - 1];
                  setThresholdsValues(newThresholds);
                  setMeasures(newMeasures);
                  setMeasuresAlerts(newMeasuresAlerts);
                  setZones(newZones);
                  setThresholdsRanges(newThresholdRanges);
                }}
                sx={{ color: palette.primary, cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default AssetInfoForm;
