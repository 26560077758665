import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getAllDocuments } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const documentSlice = createSlice({
  name: "DOCUMENT",
  initialState,
  reducers: {
    getDocumentsDataRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getDocumentDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getDocumentDataFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getDocumentsDataRequest, getDocumentDataSuccess, getDocumentDataFailure } = documentSlice.actions;

export const getDocumentsDataAction = () => {
  return async (dispatch) => {
    try {
      dispatch(getDocumentsDataRequest());
      let response = await getAllDocuments();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;
        dispatch(getDocumentDataSuccess(payload));
        return;
      }
      dispatch(getDocumentDataFailure("Invalid response"));
    } catch (error) {
      dispatch(getDocumentDataFailure(error));
    }
  };
};

export const clearDocumentsDataAction = () => {
  return async (dispatch) => {
    dispatch(getDocumentDataSuccess(null));
  };
};

export default documentSlice.reducer;
