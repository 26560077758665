import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import palette from "../../../../../config/colorPalette";
import MapAnalysis from "../MapAnalysis";
import AreaChart from "../AreaChart";
import { spatialSelectHandler } from "./utils";

const BooleanDataVisualization = ({
  title,
  measureName,
  assetName,
  asignedChannels,
  initialPointIndex,
  endPointIndex,
  boundaries,
  data,
  currentAsset,
  assetsPoints,
  loadingTemporal,
  scales,
  currentAssetValues,
  percentiles,
  differences,
  equipment,
  channel,
  selectedEquipment,
}) => {
  const intl = useIntl();
  const [startZoom, setStartZoom] = useState();
  const [endZoom, setEndZoom] = useState();
  const [loading, setLoading] = useState(true);
  const [fragmentList, setFragmentList] = useState([]);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);

  useEffect(() => {
    if (!isNil(data) && initialPointIndex !== 0 && endPointIndex !== data[0].values.length - 1) {
      setStartIndex(initialPointIndex);
      setEndIndex(endPointIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const points = assetsPoints?.data?.[`${assetName}${asignedChannels[channel - 1] + 1}`] ?? [];
  const chartData = useMemo(() => {
    const result = {
      xAxis: {
        data: data?.[0]?.pk,
      },
      yAxis: {
        data: data?.map((d) => d?.datetime?.replace(" ", "\n")),
      },
      series: data?.map((d, i) => ({
        name: d?.datetime,
        data: d?.values,
      })),
      title: `${intl.formatMessage({ id: title })} (${intl.formatMessage({ id: "waterfall" })})`,
    };
    return result;
  }, [data, title, intl]);

  useEffect(
    () =>
      setStartZoom(
        !isNil(initialPointIndex) && !isNil(chartData?.xAxis?.data)
          ? (initialPointIndex * 100) / chartData.xAxis.data.length
          : 0,
      ),
    [initialPointIndex, chartData],
  );
  useEffect(
    () =>
      setEndZoom(
        !isNil(endPointIndex) && !isNil(chartData?.xAxis?.data)
          ? (endPointIndex * 100) / chartData.xAxis.data.length
          : 100,
      ),
    [endPointIndex, chartData],
  );

  useEffect(() => {
    setLoading(loadingTemporal);
  }, [loadingTemporal]);

  useEffect(() => {
    if (
      !isNil(initialPointIndex) &&
      !isNil(endPointIndex) &&
      !isNil(chartData?.xAxis?.data) &&
      endPointIndex - initialPointIndex !== chartData?.xAxis?.data.length - 1
    ) {
      spatialSelectHandler({
        data: chartData,
        start: initialPointIndex,
        end: endPointIndex,
        setStartIndex,
        setEndIndex,
        measureName,
        setFragmentList,
        setLoading,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  if (isNil(data)) {
    return <div style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}></div>;
  }

  return (
    !isEmpty(data) && (
      <div
        style={{
          width: "99%",
          margin: "1rem",
          padding: "0.5rem",
          display: "grid",
          gap: "1rem",
          border: `1px solid ${palette.primary}`,
          position: "relative",
        }}
      >
        <h3 style={{ color: palette.primary }}>{intl.formatMessage({ id: title }).toUpperCase()}</h3>
        {isEmpty(fragmentList) && loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(176 176 176 / 60%)",
              zIndex: 2000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              position: "absolute",
            }}
          >
            {`${intl.formatMessage({ id: "loading" })}...`}
          </div>
        )}
        <div style={{ display: "flex", width: "85vw" }}>
          {/**          <AreaChart
            withToolbox
            prefix={`${measureName}-waterfall`}
            data={chartData}
            measureName={measureName}
            startZoom={startZoom}
            endZoom={endZoom}
            intl={intl}
            resetZoomHandler={() => {
              setStartZoom(0);
              setEndZoom(100);
              setFragmentList([]);
            }}
            pointsSelectHandler={(start, end) => {
              spatialSelectHandler({
                data: chartData,
                start,
                end,
                setStartIndex,
                setEndIndex,
                measureName,
                setFragmentList,
                setLoading,
                equipment,
                channel,
              });
            }}
          /> */}
          <MapAnalysis
            assetData={currentAsset}
            intl={intl}
            measureName={measureName}
            spatialDataList={data}
            temporalDataList={fragmentList}
            points={points}
            startIndex={startIndex}
            endIndex={endIndex}
            scales={scales}
            percentiles={percentiles}
            differences={differences}
            equipmentData={selectedEquipment}
          />
        </div>
      </div>
    )
  );
};

export default BooleanDataVisualization;
