import { clone, isNil } from "ramda";
import { postAsset, postEquipment, postPoint } from "../../../../api";
import { format } from "date-fns";
import { toast } from "react-toastify";

export const onSelectChangeHandler = ({ value, index, tuples, setTuples, idErrorPrefix, errors, setErrors, intl }) => {
  const newTuples = [...tuples];
  newTuples[index].type = value;

  const newErrors = { ...errors };
  for (let i = 0; i < newTuples.length; i++) {
    const item = newTuples[i];
    const id = `${idErrorPrefix}-${i}`;
    delete newErrors[id];
    const alreadyExist = newTuples.filter((t) => t.type === item.type).length > 1;
    if (alreadyExist) {
      newErrors[id] = "threshold.selected";
    }
  }

  setErrors(newErrors);
  setTuples(newTuples);
};

export const onValueChangeHandler = ({ value, index, tuples, setTuples }) => {
  if (!isNil(value)) {
    const localTuples = [...tuples];
    localTuples[index].value = value;
    setTuples(localTuples);
  }
};

export const onRemoveHandler = ({ index, tuples, setTuples }) => {
  if (tuples.length > 1) {
    const localTuples = [];
    for (let i = 0; i < tuples.length; i++) {
      if (i !== index) {
        localTuples.push(tuples[i]);
      }
    }
    setTuples(localTuples);
  }
};

export const onBlurHandler = ({
  idErrorPrefix,
  tuples,
  errors,
  setErrors,
  thresholdTypes,
  thresholdsValues,
  setThresholdsValues,
  equipment,
  measureIndex,
  channelIndex,
  zoneIndex,
  intl,
}) => {
  const newErrors = { ...errors };

  const localTuples = [...tuples];
  localTuples.sort((a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0));

  let flag = false;
  for (let i = localTuples.length - 1; i >= 0; i--) {
    const current = localTuples[i];
    const id = `${idErrorPrefix}-${i}`;
    delete newErrors[id];
    if (!isNil(current.type)) {
      if (isNil(current.value)) {
        newErrors[id] = "must.value";
        flag = true;
      } else if (i > 0) {
        const prev = localTuples[i - 1];
        if (isNil(current.value) || current.value <= prev.value) {
          newErrors[id] = `must.${thresholdTypes[prev.type]}.value`;
          flag = true;
        }
      }
    }
  }
  setErrors(newErrors);
  if (!flag) {
    let newTV = clone(thresholdsValues);
    const filteredTuples = localTuples.filter((t) => !isNil(t.value));
    newTV[equipment][measureIndex][channelIndex][zoneIndex] = filteredTuples;
    setThresholdsValues(newTV);
  }
};

export const saveAsset = async (
  assetName,
  zoom,
  demarcation,
  measures,
  measuresAlerts,
  thresholdsValues,
  track,
  centroid,
  equipments,
  image,
  alertTimeRange,
  alertTimeMeasure,
  URL,
  userdata,
  setLoadingScreen,
  thresholdsRanges,
  navigate,
  intl,
) => {
  setLoadingScreen(true);
  const measureNames = [];
  for (let i = 0; i < measures.length; i++) {
    measureNames.push(measures[i]?.[0]);
  }

  const thresholds = [];
  const thresholdsLevels = [];
  const thresholdR = [];
  for (let i = 0; i < Object.keys(thresholdsValues).length; i++) {
    thresholds.push([]);
    thresholdsLevels.push([]);
    thresholdR.push([]);

    for (let j = 0; j < thresholdsValues[`equipment${i + 1}`].length; j++) {
      thresholds[i].push([]);
      thresholdsLevels[i].push([]);
      thresholdR[i].push([]);
      for (let l = 0; l < thresholdsValues[`equipment${i + 1}`][j].length; l++) {
        thresholds[i][j].push([]);
        thresholdsLevels[i][j].push([]);
        thresholdR[i][j].push([]);
        for (let k = 0; k < thresholdsValues[`equipment${i + 1}`][j][l].length; k++) {
          thresholds[i][j][l].push([]);
          thresholdsLevels[i][j][l].push([]);
          thresholdR[i][j][l].push([]);
          if (!isNil(thresholdsRanges[`equipment${i + 1}`][j])) {
            thresholdR[i][j][l][k] = `${thresholdsRanges[`equipment${i + 1}`][j][l][k][0]}-${
              thresholdsRanges[`equipment${i + 1}`][j][l][k][1]
            }`;
          } else {
            thresholdR[i][j][l][k] = `0-1`;
          }

          for (let m = 0; m < thresholdsValues[`equipment${i + 1}`][j][l][k].length; m++) {
            thresholds[i][j][l][k].push(thresholdsValues[`equipment${i + 1}`][j][l][k][m].value);
            thresholdsLevels[i][j][l][k].push(thresholdsValues[`equipment${i + 1}`][j][l][k][m].type);
          }
        }
      }
    }
  }
  const thresholdsActives = [];
  const thresholdsNumbers = [];
  for (let i = 0; i < thresholdsLevels.length; i++) {
    thresholdsActives.push([]);
    thresholdsNumbers.push([]);
    for (let j = 0; j < thresholdsLevels[i].length; j++) {
      thresholdsActives[i].push([]);
      thresholdsNumbers[i].push([]);
      for (let l = 0; l < thresholdsLevels[i][j].length; l++) {
        thresholdsActives[i][j].push([]);
        thresholdsNumbers[i][j].push([]);
        for (let k = 0; k < thresholdsLevels[i][j][l].length; k++) {
          thresholdsActives[i][j][l].push([]);
          thresholdsNumbers[i][j][l].push([]);
          for (let m = 0; m < 3; m++) {
            if (thresholdsLevels[i][j][l][k].includes(m)) {
              thresholdsActives[i][j][l][k].push(1);
              const indexOF = thresholdsLevels[i][j][l][k].indexOf(m);
              thresholdsNumbers[i][j][l][k].push(thresholds[i][j][l][k][indexOF]);
            } else {
              thresholdsActives[i][j][l][k].push(0);
              thresholdsNumbers[i][j][l][k].push(0);
            }
          }
        }
      }
    }
  }
  const currentDate = new Date();

  const data = {
    name: assetName,
    zoom: zoom,
    centroid: centroid,
    image: image,
    demarcation: demarcation,
    dateTime: format(currentDate, "yyyy-MM-dd'T'HH:mm:ss"),
    alertTimeRange: alertTimeRange * alertTimeMeasure,
    baseUrl: URL,
  };
  try {
    const response = await postAsset(data, intl);
    if (!isNil(equipments) && !isNil(track) && !isNil(response?.status) && response?.status === 200) {
      for (let i = 0; i < equipments.length; i++) {
        let item = equipments[i];
        item.pMeasureNames = measures[`equipment${i + 1}`];
        item.pMeasureAlerts = measuresAlerts[`equipment${i + 1}`];
        item.pThresholds = thresholdsNumbers[i];
        item.pThresholdsActive = thresholdsActives[i];
        item.pThresholdTypes = ["Low", "Intermediate", "High"];
        item.pThresholdsRanges = thresholdR[i];
        item.pEnviromentMeasureNames = [];
        await postEquipment(item, intl);
      }
      await postPoint({ polylines: track, asset: assetName }, intl);
    }
    await navigate("/structures");
    window.location.reload();
    //let newUserData = clone(userdata);
    //newUserData.assets.push(assetName);
    //putUser(newUserData.id, newUserData);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
    setLoadingScreen(false);
  }

  setLoadingScreen(false);
};
