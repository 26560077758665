export const spatialSelectHandler = ({
  data,
  start,
  end,
  measureName,
  setFragmentList,
  setLoading,
  setStartIndex,
  setEndIndex,
}) => {
  setLoading(true);
  const pkList = data.xAxis.data;
  const dataList = data.series[0].data;
  const result = [];
  let j = start;
  let i = end;
  setStartIndex(j);
  setEndIndex(i);
  if (i - j >= 64) {
    let centerPoint = parseInt((i - j) / 2) + j;
    j = centerPoint >= 32 ? centerPoint - 32 : 0;
    centerPoint += 32;
    const count = data?.series?.[0]?.data?.length ?? 0;
    i = centerPoint < count ? centerPoint : count - 1;
  }
  for (j; j < i; j++) {
    result.push({
      values: dataList[j],
      datetimes: data.series[0].name,
      pk: pkList[j],
    });
  }
  setFragmentList(result);
  setLoading(false);
};
