import React from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import noImage from "../../../../../images/no-image.png";
import { Content, ImageContent, NameContent, TableContent, TableRowContent } from "./styled";
import palette from "../../../../../config/colorPalette";
import { getAlertThemeData } from "../../../../utils/utils";
import { ALERT_COLORS } from "../../../../utils/consts";
import { isNil } from "ramda";

const AlertPopup = ({ name, alarmLabel, alarmName, equipments, alarmCount, image = noImage }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <Content>
      <ImageContent style={{ border: `1px solid ${palette.primary}` }}>
        <img
          src={!isNil(image) && image.length > 0 ? "data:image/jpg;base64," + image : noImage}
          alt=""
          style={!isNil(image) && image.length > 0 ? { objectFit: "cover", width: "100%", height: "100%" } : {}}
        />
      </ImageContent>
      <NameContent>{name}</NameContent>
      {equipments.map((equipment, index) => (
        <h2>
          {equipment.equipmentID}-ALIVE:{" "}
          <span
            style={{
              backgroundColor: equipment.alive ? "green" : "red",
              width: "10px",
              height: "10px",
              display: "inline-block",
              marginLeft: "5px",
            }}
          ></span>
        </h2>
      ))}
      <TableContent>
        <TableRowContent style={{ backgroundColor: palette.primary, color: palette.likeWhite }}>
          <span>Type</span>
          <span>Quantity</span>
        </TableRowContent>

        {alarmCount.map((count, index) => {
          const alertData = getAlertThemeData(index + 1, "medium", intl);
          return (
            <TableRowContent
              onClick={() => navigate("/alarms")}
              style={{ cursor: "pointer", backgroundColor: ALERT_COLORS[index + 1], justifyContent: "space-around" }}
            >
              <span style={{ marginRight: "10%", marginLeft: "2%" }}>{alertData.icon}</span>
              <span style={{ marginRight: "10%" }}>{count}</span>
            </TableRowContent>
          );
        })}
      </TableContent>
    </Content>
  );
};

export default AlertPopup;
