import { isNil } from "ramda";
import { toast } from "react-toastify";

import { getAnalysisSpatialData, getAssetPointsEnvironmentalData, getAssetPointsSerialData } from "../../../../api";

export const loadSpatialData = async ({ asset, dateList, measureName, data, assetData, setData, setLoading }) => {
  setLoading(true);
  try {
    const result = [];
    for (let i = 0; i < dateList.length; i++) {
      const date = dateList[i];
      const isDuplicatedDate = data.filter((d) => d.datetime === date).lenght > 0;
      if (!isDuplicatedDate) {
        const spatialDataResponse = await getAnalysisSpatialData(asset, date);
        if (
          !isNil(spatialDataResponse?.status) &&
          spatialDataResponse.status === 200 &&
          !isNil(spatialDataResponse.data)
        ) {
          const spatialData = { ...spatialDataResponse.data.data?.[0] };
          const newItem = {
            asset: spatialData.asset,
            datetime: spatialData.info.datetime,
            values: spatialData[measureName],
            //pk: assetData?.points.map((p) => p.pk),
            pk: spatialData[measureName].map((d, i) => i),
          };
          result.push(newItem);
        }
      }
    }
    await setData(result);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};

export const loadTemporalData = async ({
  asset,
  startIndex,
  endIndex,
  pkList,
  measureName,
  setTemporalDataList,
  setMinsWaterfall,
  setMaxsWaterfall,
  minsWaterfall,
  maxsWaterfall,
  setLoading,
  datetime,
  equipment,
  channel,
  period,
  maximum,
}) => {
  try {
    const response =
      measureName !== "current_intensity"
        ? await getAssetPointsSerialData(
            asset,
            startIndex,
            endIndex,
            datetime,
            [measureName],
            equipment,
            channel,
            period,
            maximum,
          )
        : await getAssetPointsEnvironmentalData(asset, datetime, [measureName], equipment, period, maximum);
    const result = [];
    let minValue = Infinity;
    let maxValue = Number.NEGATIVE_INFINITY;
    if (!isNil(response?.status) && response.status === 200 && !isNil(response.data)) {
      const data = response.data?.data;

      for (let i = 0, j = startIndex; i < data.data.length; i++, j++) {
        const item = data.data[i];
        const values = item[measureName].map((item) => item.value);
        const min = Math.min(...values);
        const max = Math.max(...values);
        if (minValue > min) minValue = min;
        if (maxValue < max) maxValue = max;
        result.push({
          values: item[measureName].map((v) => v.value),
          datetimes: item[measureName].map((v) => v.datetime),
          pk: pkList[i],
        });
      }
    }
    if (Math.abs(minValue) > maxValue && measureName === "strain") {
      maxValue = Math.abs(minValue);
    }
    setMinsWaterfall({ ...minsWaterfall, [measureName]: minValue });
    setMaxsWaterfall({ ...maxsWaterfall, [measureName]: maxValue });
    setTemporalDataList(result);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  } finally {
    if (measureName === "current_intensity") {
      setLoading(false);
    }
  }
};

export const spatialSelectHandler = ({
  data,
  start,
  end,
  setStartIndex,
  setEndIndex,
  measureName,
  setTemporalDataList,
  setEnvironmentalDataList,
  minsWaterfall,
  setMinsWaterfall,
  maxsWaterfall,
  setMaxsWaterfall,
  currentAsset,
  spatialDataList,
  setLoading,
  datetime,
  equipment,
  channel,
  period,
  maximum,
}) => {
  setLoading(true);
  let i = start;
  let j = end;
  setStartIndex(i);
  setEndIndex(j);
  if (j - i >= 64) {
    let centerPoint = parseInt((j - i) / 2) + i;
    i = centerPoint >= 32 ? centerPoint - 32 : 0;
    centerPoint += 32;
    const count = data?.series?.[0]?.data?.length ?? 0;
    j = centerPoint < count ? centerPoint : count - 1;
  }
  loadTemporalData({
    asset: currentAsset.name,
    startIndex: i,
    endIndex: j,
    pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
    measureName,
    setTemporalDataList,
    setMinsWaterfall,
    setMaxsWaterfall,
    minsWaterfall,
    maxsWaterfall,
    setLoading,
    datetime,
    equipment,
    channel,
    period,
    maximum,
  });
  if (i === j) {
    loadTemporalData({
      asset: currentAsset.name,
      pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
      measureName: "current_intensity",
      setTemporalDataList: setEnvironmentalDataList,
      setMinsWaterfall,
      setMaxsWaterfall,
      minsWaterfall,
      maxsWaterfall,
      setLoading,
      datetime,
      equipment,
      period,
      maximum,
      channel: null,
      startIndex: null,
    });
  }
  if (i !== j) {
    setLoading(false);
  }
};
