import { isNil } from "ramda";

import { verifyRequestError } from "../../../utils/utils";
import { deleteAlert, getEquipments } from "../../../api";

export const loadEquipments = async ({ assetName, setEquipments, setLoading, dispatch, navigate, intl }) => {
  setLoading(true);
  try {
    const response = await getEquipments();
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      const equipments = response.data.data;
      await setEquipments(equipments);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
};

export const getColumns = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", hide: true, disableExport: true },
    { field: "asset", headerName: intl.formatMessage({ id: "structure" }), width: 100 },
    { field: "name", headerName: intl.formatMessage({ id: "name" }), width: 125 },
    { field: "equipmentID", headerName: intl.formatMessage({ id: "equipment.equipmentID" }), width: 200 },
    { field: "ipAddress", headerName: "IP", width: 150 },
    { field: "statusDetails", headerName: intl.formatMessage({ id: "status.equipment" }), width: 200 },
  ];
  return columns;
};

export const handleDeleteAlert = async ({
  id,
  alerts,
  setEquipments,
  setLoading,
  setOpenTextModal,
  dispatch,
  navigate,
  intl,
}) => {
  if (isNil(id)) {
    // delete all
    if (!isNil(alerts)) {
      try {
        await alerts.forEach(async (item) => {
          await deleteAlert(item.id);
        });
        loadEquipments({ setEquipments, setLoading, dispatch, navigate, intl });
        setOpenTextModal(false);
      } catch (error) {
        throw Error("The alert was not deleted");
      }
    }
    return;
  }
  try {
    const response = await deleteAlert(id);
    if (!isNil(response)) {
      loadEquipments({ setEquipments, setLoading, dispatch, navigate, intl });
      setOpenTextModal(false);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
};
