import { Button } from "@mui/material";
import React, { useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents, Polyline, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import { isEmpty, isNil } from "ramda";
import palette, { polylineColors } from "../../../../../config/colorPalette";
import { IS_ONLINE, MAP_TYPES } from "../../../../../config/configmap";
import { MapTypeContent } from "../../../Home/components/Map/styled";
import MapTypeSelector from "../../../Home/components/MapTypeSelector";

const PolylineData = ({
  setPolyline,
  polylineDisplay,
  setPolylineDisplay,
  centroid,
  setCentroid,
  pIndexes,
  setPIndexes,
  intl,
}) => {
  const customIcon = new Icon({
    iconUrl: require("../../../../../images/Uptech-marker-icon.png"),
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });
  const [error, setError] = useState("");

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      const extension = event.target.files[0].name.split(".").pop();
      const reader = new FileReader();
      let read = [];
      reader.onload = (e) => {
        if (extension === "json") {
          read = JSON.parse(e.target.result);
        } else if (extension === "csv") {
          read = parseCSVToJson(e.target.result);
        }
        setPolyline(read.polylines);
        //setPIndexes(read.p_indexes);
        try {
          //if (!read.asset || !read.points || !read.p_indexes) {
          //  setPolyline([]);
          //  setPIndexes(null);
          //  setPolylineDisplay([]);
          //  throw new Error("bad.structure");
          //}
          //const result = read.points.map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }));
          const modifiedPolylines = read.polylines.map((polyline) => {
            const modifiedPoints = polyline.points.map((point) => ({
              ...point,
              lat: point.latitude, // Cambiando 'latitude' por 'lat'
              lng: point.longitude, // Cambiando 'longitude' por 'lng'
            }));

            return {
              ...polyline,
              points: modifiedPoints,
            };
          });

          setPolylineDisplay(modifiedPolylines);
          setError("");
        } catch (error) {
          setError("bad.structure");
        }
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  function parseCSVToJson(csvContent) {
    const lines = csvContent.split("\n");
    const headers = lines[0]?.split(",");
    const jsonData = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j];
      }
      jsonData.push(entry);
    }

    return jsonData;
  }

  const [mapType, setMapType] = useState("regular");

  const AddCentroid = () => {
    // eslint-disable-next-line no-unused-vars
    const map = useMapEvents({
      click: (e) => {
        setCentroid([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };
  const MyComponentSetView = ({ polyline }) => {
    const map = useMap();
    if (!isEmpty(polyline) && !isNil(polyline[0].points) && !isEmpty(polyline[0].points))
      map.fitBounds(polyline[0].points);
    return null;
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1%" }}>
        <input type="file" accept=".csv, .json" style={{ display: "none" }} id="file-input" onChange={handleChange} />
        <label htmlFor="file-input">
          <Button variant="contained" component="span">
            Subir Archivo
          </Button>
        </label>
        <h3 style={{ color: "red", marginLeft: "1rem" }}>{error.length > 0 && intl.formatMessage({ id: error })}</h3>
      </div>
      <MapContainer
        center={
          !isEmpty(polylineDisplay) && polylineDisplay[0]?.points.length > 0
            ? polylineDisplay[0]?.points[Math.round(polylineDisplay[0]?.points.length / 2)]
            : [27.337882, -27.919657]
        }
        zoom={!isEmpty(polylineDisplay) && polylineDisplay[0]?.points.length > 0 ? 10 : 3}
        style={{ height: "calc(100vh - 320px)", width: "165vh" }}
        anima
      >
        <TileLayer
          attribution={IS_ONLINE ? "Google Maps" : "OpenStreetMap"}
          url={MAP_TYPES.find((type) => type.value === mapType).tileLayer}
          maxZoom={16}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <MyComponentSetView polyline={polylineDisplay} />
        {polylineDisplay.length > 0 &&
          // eslint-disable-next-line array-callback-return
          polylineDisplay.map((polyline, index) => {
            // eslint-disable-next-line array-callback-return
            return polyline.points.map((point, indice) => {
              if (indice === 0) {
                return null;
              }

              if (polyline.p_indexes.includes(indice)) {
                const previousIndex = polyline.p_indexes[polyline.p_indexes.indexOf(indice) - 1];
                const center = [
                  [polylineDisplay[index].points[previousIndex].lat, polylineDisplay[index].points[previousIndex].lng],
                  [point.lat, point.lng],
                ];
                const color = polylineColors[index];
                return <Polyline key={indice + "_" + index} color={color} positions={center} weight={5}></Polyline>;
              }
            });
          })}
        <AddCentroid />
        {centroid.length > 0 && <Marker key={"Centroid"} position={centroid} icon={customIcon}></Marker>}

        {IS_ONLINE ?? (
          <MapTypeContent>
            <MapTypeSelector items={MAP_TYPES} onChange={setMapType} color={palette.dark} />
          </MapTypeContent>
        )}
      </MapContainer>
    </div>
  );
};
export default PolylineData;
