import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import palette from "../../../../../config/colorPalette";
import { getMuiTheme } from "../../../../utils/utils";
import { loadDateOptionsChannel } from "../../utils";
import { LoadingButton } from "@mui/lab";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
const AddTraceModal = ({
  open,
  setOpen,
  handleModalAccept,
  dateOptionsModal,
  timeOptionsModal,
  setTimeOptionsModal,
  setDateOptionsModal,
  asset,
  equipment,
  channel,
}) => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);

  const [date, setDate] = useState(0);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);

  const enabledDates = useMemo(() => {
    if (!isNil(dateOptionsModal) && !isEmpty(dateOptionsModal)) {
      return dateOptionsModal;
    }
  }, [dateOptionsModal]);

  const shouldDisableDate = (date) => {
    // Convertir la fecha a una cadena de fecha sin hora para compararla con las fechas habilitadas
    const dateString = format(date, "yyyy-MM-dd");
    return !enabledDates.some((enabledDate) => enabledDate === dateString);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle style={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>
            {intl.formatMessage({ id: "edit profile" })}
          </DialogTitle>
          <DialogContent style={{ paddingBottom: "0" }}>
            <form autoComplete="off">
              <div>
                <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      gap: "0.5rem",
                      padding: "1rem",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          id="date_picker"
                          label={intl.formatMessage({ id: "date" })}
                          shouldDisableDate={shouldDisableDate}
                          format="dd-MM-yyyy"
                          value={parseISO(dateOptionsModal[date])}
                          onChange={(e) => {
                            setLoading(true);
                            const date = new Date(e);

                            const year = date.getFullYear();
                            const month = ("0" + (date.getMonth() + 1)).slice(-2);
                            const day = ("0" + date.getDate()).slice(-2);

                            const formattedDate = year + "-" + month + "-" + day;
                            loadDateOptionsChannel({
                              asset,
                              equipment,
                              channel,
                              setDateOptionsModal,
                              setTimeOptionsModal,
                              date: formattedDate,
                              setDate,
                              setTime,
                              setLoading,
                            });
                          }}
                        />
                      </LocalizationProvider>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="trace_date"
                        label={intl.formatMessage({ id: "time" })}
                        select
                        style={{ width: 150 }}
                        value={time}
                        onChange={(event) => {
                          setTime(event.target.value);
                        }}
                      >
                        {timeOptionsModal?.map((opt, i) => (
                          <MenuItem key={`times-${i}`} value={i}>
                            {opt}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "1rem" }} />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: palette.secondary, color: "white" }}
              onClick={() => setOpen(false)}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading ?? false}
              style={{
                backgroundColor: loading ? palette.light : palette.primary,
                color: "white",
              }}
              onClick={async () => {
                handleModalAccept(date, time);
                setOpen(false);
              }}
            >
              {intl.formatMessage({ id: "accept" })}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default AddTraceModal;
