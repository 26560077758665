import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { isNil } from "ramda";
import { LanguageOutlined } from "@mui/icons-material";

const Selector = ({ items, color = "black", onChange = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <IconButton
        data-testid="lang-btn"
        size="large"
        aria-controls="language-menu"
        aria-haspopup
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <LanguageOutlined style={{ color }} />
      </IconButton>
      <Menu
        id="language-menu"
        data-testid="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!isNil(items) &&
          items.map((item) => (
            <MenuItem
              key={item.value}
              data-testid={`lang-menu-item-${item.value}`}
              value={item.value}
              onClick={() => {
                onChange(item.value);
                setAnchorEl(null);
              }}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default Selector;
