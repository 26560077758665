import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";
import { format } from "date-fns";

import ToolbarDeleteIcon from "@mui/icons-material/Delete";
import { Box, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { getGridLocalTextTheme, getGridTheme, menuAccessVerification } from "../../utils/utils";
import palette from "../../../config/colorPalette";
import TextModal from "../../components/TextModal";
import EditToolbar from "../../components/EditToolbar";
import { getColumns, handleDeleteAlert, loadEquipments } from "./utils/utils";
import { setSelectedMenuOptionAction } from "../../redux/menuSlice";
import { putEquipment } from "../../api";

const Equipments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { assetName } = useParams();

  const assetsData = useSelector((state) => state.assetsData);

  const userData = useSelector((state) => state.userData.data);
  const menuData = useSelector((state) => state.menuData.data);
  const localeData = useSelector((state) => state.configData.locale);

  const gridLocalTextTheme = useMemo(() => getGridLocalTextTheme(localeData), [localeData]);
  const gridTheme = useMemo(() => getGridTheme(), []);

  const [loading, setLoading] = useState(true);
  const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
  const [equipments, setEquipments] = useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const [switchStates, setSwitchStates] = useState({});

  const columns = useMemo(() => {
    let result = [];
    if (!isNil(equipments) && !isNil(userData) && !isNil(assetsData?.data)) {
      let newEquipments = equipments;
      if (userData.role !== "admin") {
        newEquipments = equipments.filter((e) => e.asset !== "IBIZA-FORMENTERA");
      }
      result = getColumns(intl);

      const latitudeColumn = {
        field: "latitude",
        headerName: intl.formatMessage({ id: "latitude" }),
        width: 130,
        renderCell: ({ id }) => {
          const equipment = newEquipments.find((equip) => equip.id === id);
          return <>{decimalToDMS(equipment.latitude)}</>;
        },
      };
      const longitudeColumn = {
        field: "longitude",
        headerName: intl.formatMessage({ id: "longitude" }),
        width: 130,
        renderCell: ({ id }) => {
          const equipment = newEquipments.find((equip) => equip.id === id);
          return <>{decimalToDMS(equipment.longitude)}</>;
        },
      };

      // Creamos dos nuevas columnas para el switch y el botón de acciones
      const switchColumn = {
        field: "switch",
        headerName: intl.formatMessage({ id: "notification.status" }),
        width: 150,
        renderCell: ({ id }) => {
          const snsValue = newEquipments.find((equip) => equip.id === id)?.supportNotificationStatus || false;
          const switchState = switchStates[id] ?? snsValue;

          return (
            <Switch
              onChange={(e) => {
                const newSwitchStates = { ...switchStates };
                if (switchState) {
                  newSwitchStates[id] = false;
                  const obj = newEquipments.find((equip) => equip.id === id);
                  if (obj) {
                    obj.supportNotificationStatus = false;
                    putEquipment(obj);
                  }
                } else {
                  Object.keys(newSwitchStates).forEach((key) => {
                    newSwitchStates[key] = key === id;
                  });
                }
                setSwitchStates(newSwitchStates);
              }}
              defaultChecked={switchState}
              disabled={!switchState}
            />
          );
        },
      };
      const sendColumn = {
        field: "measurementState",
        headerName: intl.formatMessage({ id: "measurement.state" }),
        width: 175,
        renderCell: ({ id }) => {
          const equipment = newEquipments.find((equip) => equip.id === id);
          const snsValue = equipment?.measurementState || false;
          const disable = equipment.manufacturer !== "Uptech Sensing" && equipment.manufacturer !== "AP Sensing";
          return (
            <Switch
              onChange={(e) => {
                const obj = newEquipments.find((equip) => equip.id === id);
                obj.measurementState = e.target.checked;
                putEquipment(obj);
              }}
              disabled={disable}
              defaultChecked={snsValue}
            />
          );
        },
      };
      // Reemplazamos la última columna con las dos nuevas columnas
      result.push(latitudeColumn, longitudeColumn, switchColumn, sendColumn);
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, equipments, userData, assetsData, switchStates]);

  function decimalToDMS(decimal) {
    var degrees = Math.floor(decimal);
    var minutes = Math.floor((decimal - degrees) * 60);
    var seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);

    // Asegurar que los segundos tengan dos dígitos
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return degrees + "° " + minutes + "' " + seconds + '"';
  }

  useEffect(() => {
    if (menuAccessVerification("/alarms", menuData, navigate)) {
      const i = menuData.find((m) => m.page === "/alarms").value;
      dispatch(setSelectedMenuOptionAction(i));
      loadEquipments({ assetName, setEquipments, setLoading, dispatch, navigate, intl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  if (isNil(equipments) || isNil(assetsData?.data) || loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>
        {intl.formatMessage({ id: "equipments" })}
      </div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(equipments) || isEmpty(equipments) ? 400 : "100%",
          width: "100%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
        }}
      >
        <DataGrid
          rows={equipments?.map((item) => ({ ...item, customValue: item.value }))}
          columns={columns}
          columnVisibilityModel={{ id: false, type: false, value: false }}
          loading={loading}
          localeText={gridLocalTextTheme.components.MuiDataGrid.defaultProps.localeText}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          disableRowSelectionOnClick
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar:
              userData?.role === "admin"
                ? {
                    btnText: intl.formatMessage({ id: "delete all" }),
                    btnIcon: <ToolbarDeleteIcon />,
                    handleClick: () => setOpenDeleteAllModal(true),
                    btnEnabled: !isNil(equipments) && !isEmpty(equipments),
                    excelExportOptions: {
                      data: { rows: equipments, columns: columns },
                      filename: `${format(new Date(), "yyyyMMdd")}_${intl.formatMessage({
                        id: "alarms",
                      })}_${intl.formatMessage({ id: "project.name" })}`,
                    },
                  }
                : {},
          }}
          sx={gridTheme}
        />
      </Box>
      <TextModal
        open={openDeleteAllModal}
        setOpen={setOpenDeleteAllModal}
        title={intl.formatMessage({ id: "delete all equipments" })}
        text={intl.formatMessage({ id: "delete.all.equipments.text" })}
        acceptFunction={() =>
          handleDeleteAlert({
            id: null,
            equipments,
            setEquipments,
            setLoading,
            setOpenTextModal: setOpenDeleteAllModal,
            dispatch,
            navigate,
            intl,
          })
        }
      />
    </div>
  );
};

export default Equipments;
