import { isNil } from "ramda";

import { deleteUser, getAllUsersData } from "../../../api";
import { verifyRequestError } from "../../../utils/utils";

export const loadUsers = async (setUsers, setLoading, dispatch, navigate, intl) => {
  setLoading(true);
  try {
    const response = await getAllUsersData();
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      await setUsers(response.data.data);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
};

export const getColumns = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", width: 180, hide: true },
    { field: "avatar", headerName: "", width: 50 },
    { field: "name", headerName: intl.formatMessage({ id: "name" }), width: 240 },
    { field: "username", headerName: intl.formatMessage({ id: "email" }), width: 250 },
    { field: "role", headerName: intl.formatMessage({ id: "role" }), width: 120 },
    { field: "alertsType", headerName: intl.formatMessage({ id: "alarms" }), width: 100 },
    { field: "demarcations", headerName: intl.formatMessage({ id: "demarcations" }), width: 200, flex: 1 },
    { field: "organization", headerName: intl.formatMessage({ id: "organization" }), width: 150 },
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "actions" }),
      width: 100,
      cellClassName: "actions",
      getActions: null,
    },
  ];
  return columns;
};

export const handleDeleteUser = async ({ id, setUsers, setLoading, setOpenTextModal, dispatch, navigate, intl }) => {
  if (isNil(id)) {
    return;
  }
  try {
    const response = await deleteUser(id, intl);
    if (!isNil(response)) {
      loadUsers(setUsers, setLoading, dispatch, navigate);
      setOpenTextModal(false);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
};
