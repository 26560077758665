import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getEquipmentTypes } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
  locale: navigator.language.split(/[-_]/)?.[0],
};

const equipmentTypeSlice = createSlice({
  name: "CONFIG",
  initialState,
  reducers: {
    setLocaleDataAction: (state, action) => {
      state.locale = action.payload;
    },
    getEquipmentTypesRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getEquipmentTypesSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getEquipmentTypesFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getEquipmentTypesRequest, getEquipmentTypesSuccess, getEquipmentTypesFailure, setLocaleDataAction } =
  equipmentTypeSlice.actions;

export const getEquipmentTypesAction = () => {
  return async (dispatch) => {
    try {
      dispatch(getEquipmentTypesRequest());
      let response = await getEquipmentTypes();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;
        dispatch(getEquipmentTypesSuccess(payload));
        return;
      }
      dispatch(getEquipmentTypesFailure("Invalid response"));
    } catch (error) {
      dispatch(getEquipmentTypesFailure(error));
    }
  };
};

export const clearEquipmentTypesAction = () => {
  return async (dispatch) => {
    dispatch(getEquipmentTypesSuccess(null));
  };
};

export default equipmentTypeSlice.reducer;
