import { isNil } from "ramda";
import { AUTH_API_ENDPOINT } from "../../config/configmap";
import { createCookie, eraseCookie, readCookie } from "../cookie";

const saveAuthenticationVariables = (token, expirationTime, refreshToken, userId, remember) => {
  eraseCookie("uptechAccessToken");
  eraseCookie("uptechTokenTime");
  eraseCookie("uptechRefreshToken");
  eraseCookie("uptechUID");
  createCookie("uptechAccessToken", token, remember ? 30 : 1);
  createCookie("uptechTokenTime", expirationTime, remember ? 30 : 1);
  createCookie("uptechRefreshToken", refreshToken, remember ? 30 : 1);
  createCookie("uptechUID", userId, remember ? 30 : 1);
};

const computeTokenExpirationTime = (minutes) => {
  const currentTime = Date.now();
  const futureTime = new Date(currentTime);
  return futureTime.setTime(currentTime + minutes * 60 * 1000);
};

export const setAuthToken = (API, token) => {
  if (!isNil(API?.defaults?.headers?.common)) {
    if (!isNil(token)) {
      API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete API.defaults.headers.common["Authorization"];
    }
  }
};

export const authFunction = async (API, type, body) => {
  if (!isNil(API)) {
    let endpoint = type;
    let what;
    switch (type) {
      case "login":
        what = "Login";
        break;
      case "register":
        what = "Register";
        break;
      case "refreshToken":
        what = "Token refresh";
        if (!userWantToBeRemember()) {
          logout();
          endpoint = null;
        }
        break;
      default:
        endpoint = null;
        break;
    }
    try {
      if (!isNil(endpoint)) {
        const response = await API.post(`${AUTH_API_ENDPOINT}/${endpoint}`, body);
        const { accessToken, expirationTime, refreshToken, role, id } = response.data.data;
        const computedExpirationTime = computeTokenExpirationTime(expirationTime);
        saveAuthenticationVariables(
          accessToken,
          computedExpirationTime,
          refreshToken,
          !isNil(id) ? id : getFromCookie("uptechUID"),
          body.remember_me,
        );
        setAuthToken(API, accessToken);

        return endpoint !== "refreshToken" ? { username: body.username, id, role } : true;
      }
    } catch (error) {
      throw Error(`${what} failed: ${error.response.data.detail}`);
    }
  }
  return false;
};

export const logout = () => {
  eraseCookie("uptechRememberMe");
  eraseCookie("uptechAccessToken");
  eraseCookie("uptechTokenTime");
  eraseCookie("uptechRefreshToken");
  eraseCookie("uptechUID");
};

export const isTokenExpired = () => {
  const expirationTime = getFromCookie("uptechTokenTime");
  if (!isNil(expirationTime)) {
    return Date.now() > parseInt(expirationTime);
  }
  return false;
};

export const userWantToBeRemember = () => {
  const remember = readCookie("uptechRememberMe");
  return !isNil(remember);
};

export const getFromCookie = (name) => {
  const item = readCookie(name);
  if (isNil(item)) {
    logout();
    return null;
  }
  return item;
};

export const isAuthenticated = async (API) => {
  const token = getFromCookie("uptechAccessToken");
  if (!isNil(token)) {
    if (!isTokenExpired()) {
      setAuthToken(API, token);
      return true;
    }
    const rfToken = getFromCookie("uptechRefreshToken");
    const refreshSuccess = await authFunction(API, "refreshToken", rfToken);
    return refreshSuccess;
  }
  return false;
};

export const isOldPasswordValid = async (API, body) => {
  try {
    const response = await API.post(`${AUTH_API_ENDPOINT}/oldPasswordVerification`, body);
    return response;
  } catch (error) {
    throw Error("old.password.verification.error.message");
  }
};
