import React, { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
} from "@mui/material";
import { useIntl } from "react-intl";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { getMuiTheme, validateEmail } from "../../../utils/utils";
import palette from "../../../../config/colorPalette";
import { TextBox } from "../styled";
import { putForgotPassword } from "../../../api";

const ForgetPasswordModal = ({ open, setOpen }) => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);

  const [email, setEmail] = useState("");

  const disabled = useMemo(() => {
    if (validateEmail(email) && email.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [email]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle tyle={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>
            <TextBox size="2rem">{intl.formatMessage({ id: "reset.password" })}</TextBox>
            <TextBox size="1rem">{intl.formatMessage({ id: "send.password.email" })}</TextBox>
          </DialogTitle>
          <DialogContent>
            <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="email">{intl.formatMessage({ id: "email" })}</InputLabel>
              <OutlinedInput
                id="email"
                type="email"
                required
                endAdornment={
                  <InputAdornment position="end">
                    <AlternateEmailIcon color="#e4e6ef" />
                  </InputAdornment>
                }
                label={intl.formatMessage({ id: "email" })}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: palette.secondary, color: "white" }}
              onClick={() => setOpen(false)}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: disabled ? palette.light : palette.primary,
              }}
              disabled={disabled}
              onClick={async () => {
                putForgotPassword(email, intl);
              }}
            >
              {intl.formatMessage({ id: "send" })}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default ForgetPasswordModal;
