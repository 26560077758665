import { clone, isNil } from "ramda";

import { postUser, putUser } from "../../../../../api";
import { validateEmail, validatePassword, verifyRequestError } from "../../../../../utils/utils";

export const handleClose = (setLocalUser, setOpen) => {
  setLocalUser(null);
  setOpen(false);
};

export const handleModalAccept = async (data, type, dispatch, navigate, intl) => {
  if (type === "view") {
    handleClose();
    return false;
  }
  try {
    let response = null;
    if (type === "create") {
      let dataWithFirstTime = clone(data);
      dataWithFirstTime.firstTime = true;
      response = await postUser(dataWithFirstTime, intl);
    } else if (type === "edit") {
      response = await putUser(data.id, data, intl);
    }
    if (!isNil(response)) {
      return true;
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  return false;
};

export const handleChanges = ({ who, value, setError, setData, password, intl }) => {
  if (who === "email") {
    const isValid = validateEmail(value);
    setError(!isValid ? "email.verification.error.message" : null);
    setData(value);
  }
  if (who === "password") {
    const isValid = validatePassword(value);
    setError(!isValid ? "password.verification.error.message" : null);
    setData(value);
  }
  if (who === "secondPassword") {
    const isValid = validatePassword(value);
    setError(
      !isValid
        ? "password.verification.error.message"
        : value !== password
        ? "passwords.comparison.verification.error.message"
        : null,
    );
    setData(value);
  }
};
