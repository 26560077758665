import React from "react";
import { useIntl } from "react-intl";

import workingImage from "../../../images/workin-on-it.png";
import { Content } from "./styled";
import palette from "../../../config/colorPalette";

const WorkingPage = () => {
  const intl = useIntl();

  return (
    <Content>
      <img src={workingImage} alt="working on it" />
      <div style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "space-around" }}>
        <div></div>
        <div
          style={{
            fontFamily: "fagoco",
            fontWeight: 600,
            color: palette.primary,
            fontSize: "xx-large",
            width: "35%",
            textAlign: "center",
          }}
        >
          {intl.formatMessage({ id: "working.page" })}
        </div>
      </div>
    </Content>
  );
};

export default WorkingPage;
