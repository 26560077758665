import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getAssetsValues } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const assetsValueSlice = createSlice({
  name: "ASSET_VALUES",
  initialState,
  reducers: {
    getAssetsValuesRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getAssetsValuesSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAssetsValuesFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
    setAssetsData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { getAssetsValuesRequest, getAssetsValuesSuccess, getAssetsValuesFailure, setAssetsData } =
  assetsValueSlice.actions;

export const getAssetsValuesAction = (assets) => {
  return async (dispatch) => {
    try {
      dispatch(getAssetsValuesRequest());
      let response = await getAssetsValues();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        const data = response.data.data;
        const payload = data.map((d) => {
          const asset = assets.find((a) => a.name === d.asset);
          const result = { asset: d.asset, boundaries: d.boundaries };
          for (let i = 0; i < asset.pMeasureNames.length; i++) {
            const m = asset.pMeasureNames[i];
            result[m] = d[m];
          }
          return result;
        });

        dispatch(getAssetsValuesSuccess(payload));
        return;
      }
      dispatch(getAssetsValuesFailure("Invalid response"));
    } catch (error) {
      dispatch(getAssetsValuesFailure(error));
    }
  };
};

export const clearAssetsValuesAction = () => {
  return async (dispatch) => {
    dispatch(getAssetsValuesSuccess(null));
  };
};

export default assetsValueSlice.reducer;
