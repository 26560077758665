import React from "react";
import { useLocation } from "react-router-dom";
import { useDownload } from "./DownloadContext";
import palette from "../config/colorPalette";
import { Box, Button, LinearProgress } from "@mui/material";
import { useIntl } from "react-intl";

const FloatingDownloadIndicator = () => {
  const { downloadState, cancelDownload } = useDownload();
  const location = useLocation();
  const intl = useIntl();

  const isDownloadPage = location.pathname === "/downloads";

  // No mostrar el indicador si no hay descarga en progreso o si estamos en la página de descarga
  if (!downloadState.isDownloading || isDownloadPage) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        width: 300,
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 2,
        p: 2,
        zIndex: 10000000000000000,
      }}
    >
      <Box sx={{ width: "100%", mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={downloadState.progress}
          sx={{
            backgroundColor: palette.light,
            "& .MuiLinearProgress-bar": {
              backgroundColor: palette.primary,
            },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <div>{`${intl.formatMessage({ id: "downloading" })}: ${downloadState.asset?.name}`}</div>
        </Box>
        <Button
          variant="contained"
          style={{
            height: "2.5rem",
            backgroundColor: palette.primary,
            color: "white",
          }}
          onClick={cancelDownload}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
      </Box>
    </Box>
  );
};

export default FloatingDownloadIndicator;
