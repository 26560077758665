import React, { useState } from "react";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import { IconButton, Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MapIcon from "@mui/icons-material/Map";

const MapTypeSelector = ({ items, color, onChange = () => {} }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Tooltip title={intl.formatMessage({ id: "map.change.type" })} arrow placement="top">
        <IconButton
          size="large"
          aria-controls="map-type-selector"
          aria-haspopup
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ backgroundColor: "white", border: "1px solid gray" }}
        >
          <MapIcon style={{ color }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="map-type-selector"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!isNil(items) &&
          items.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => {
                onChange(item.value);
                setAnchorEl(null);
              }}
            >
              {intl.formatMessage({ id: item.label })}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default MapTypeSelector;
