import styled from "styled-components";

export const AvatarUploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  border-radius: 5.5rem;

  &:hover {
    opacity: 1;
  }
`;

export const ErrorText = styled.span`
  margin: 2px 5px;
  font-size: 0.6rem;
  color: red;
`;
