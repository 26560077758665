import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
  gap: 5vh;
`;

export const LinkBox = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.1rem;
  align-items: center;
  color: ${({ color }) => color ?? "#009cde"};
`;
