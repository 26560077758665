import React, { createContext, useState, useContext } from "react";

const DownloadContext = createContext();

export const DownloadProvider = ({ children }) => {
  const [downloadState, setDownloadState] = useState({
    isDownloading: false,
    progress: 0,
    asset: null,
    startDate: null,
    endDate: null,
  });
  const [downloadController, setDownloadController] = useState(null);

  const startDownload = (controller, asset, startDate, endDate) => {
    setDownloadState({
      isDownloading: true,
      progress: 0,
      asset,
      startDate,
      endDate,
    });
    setDownloadController(controller);
  };

  const updateProgress = (progress) => {
    setDownloadState((prevState) => ({ ...prevState, progress }));
  };

  const finishDownload = () => {
    setDownloadState({
      isDownloading: false,
      progress: 0,
      asset: null,
      startDate: null,
      endDate: null,
    });
    setDownloadController(null);
  };

  const cancelDownload = () => {
    if (downloadController) {
      downloadController.abort();
    }
  };

  return (
    <DownloadContext.Provider
      value={{
        downloadState,
        startDownload,
        updateProgress,
        finishDownload,
        cancelDownload,
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
};

export const useDownload = () => useContext(DownloadContext);
