const palette = {
  primary: "#861a21", //"rgb(134 26 34)",
  secondary: "#2f3234", //"rgb(47 50 52)",
  light: "#b0b0a9", //"rgb(176 176 169)",
  medium: "#575d5e", //"rgb(87 93 94)",
  dark: "#0e0e10", //"rgb(14 14 16)",
  dividerLight: "#861a2228", //"rgb(134 26 34 / 28%)",
  likeWhite: "#ecece7", //"rgb(236 236 231)",
  link: "#861a2268", //"rgb(134 26 34 / 68%)",
};

export const bluePalette = {
  primary: "#02457A",
  secondary: "#97CADB",
  light: "#D6E8EE",
  medium: "#018ABE",
  dark: "#001B48",
  dividerLight: "#d6e8ee28", //"rgb(214 232 238 / 28%)",
  likeWhite: "white",
};

export const polylineColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

export default palette;
