import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { clone, isEmpty, isNil } from "ramda";
import { useDownload } from "../../../App/DownloadContext";

import palette from "../../../config/colorPalette";
import { Autocomplete, Box, Button, Checkbox, LinearProgress, MenuItem, TextField } from "@mui/material";
import { getAssetsDataAction } from "../../redux/assetsSlice";
import { getConfigDataAction } from "../../redux/configSlice";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
import { generateCSV, loadDateOptions } from "./utils";
import { getAnalysisSpatialData, getPointsByAssets } from "../../api";
import JSZip from "jszip";
import { toast } from "react-toastify";

const Download = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const assets = useSelector((state) => state.assetsData);
  const currentConfig = useSelector((state) => state.configData);
  const currentUser = useSelector((state) => state.userData.data);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedChannels, setSelectedChannels] = useState(null);
  const autocompleteRef = useRef(null);
  const [dateOptions, setDateOptions] = useState([]);
  const [dateTimes, setDateTimes] = useState([]);
  const [date, setDate] = useState(null);
  const [date2, setDate2] = useState(null);
  const [points, setPoints] = useState(null);

  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { downloadState, startDownload, updateProgress, finishDownload, cancelDownload } = useDownload();
  useMemo(() => {
    if (!isNil(assets.data) && !isEmpty(assets.data) && !isNil(currentConfig.data)) {
      setSelectedAsset(assets.data[0]);
      setSelectedEquipment(assets.data[0].equipments[0]);
      const aux = [];
      for (let index = 0; index < assets.data[0].equipments[0].channels.length; index++) {
        aux.push(index);
      }
      setSelectedChannels(aux);
    }
  }, [assets, currentConfig]);

  useEffect(() => {
    async function getDatesAndPoints() {
      if (!isNil(selectedAsset)) {
        const selectedEquip = selectedAsset.equipments[0];
        setSelectedEquipment(selectedEquip);
        const aux = [];
        for (let index = 0; index < selectedEquip.channels.length; index++) {
          aux.push(index);
        }
        setSelectedChannels(aux);
        loadDateOptions({
          asset: selectedAsset.name,
          selectedChannels: aux,
          setDateOptions,
          setDate,
          setDateTimes,
          setDate2,
        });
        const pointsResponse = await getPointsByAssets(selectedAsset.name);
        setPoints(pointsResponse.data.data);
      }
    }
    getDatesAndPoints();
  }, [selectedAsset]);

  useEffect(() => {
    setTimeout(() => {
      setDisableButton(false);
    }, 500);
  }, [points]);

  useEffect(() => {
    if (!isNil(currentUser)) {
      dispatch(getAssetsDataAction(currentUser.id));
      dispatch(getConfigDataAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const enabledDates = useMemo(() => {
    if (!isNil(dateOptions) && !isEmpty(dateOptions)) {
      setDisableButton(false);
      return dateOptions;
    }
  }, [dateOptions]);
  const formatDate = (date) => format(date, "yyyy-MM-dd");

  const shouldDisableDate = (date) => {
    const dateString = formatDate(date);
    return !enabledDates.some((enabledDate) => enabledDate === dateString);
  };

  const downloadData = async (signal) => {
    setLoading(true);
    let zip = new JSZip();
    const startDate = new Date(dateOptions[date]);
    const endDate = new Date(dateOptions[date2]);
    let newDate1 = startDate;
    let newDate2 = endDate;
    let asset = null;
    let equipmentID = null;
    let channel = null;

    startDate.setUTCHours(0, 0, 0, 0);

    endDate.setUTCHours(23, 59, 59, 999);
    const filteredData = dateTimes.filter((item) => {
      const itemDate = new Date(item.dateTime);
      return itemDate >= startDate && itemDate <= endDate;
    });
    //por cada canal un zip
    try {
      const totalItems = selectedChannels.length * filteredData.length;
      let processedItems = 0;

      for (let indexChannel = 0; indexChannel < selectedChannels.length; indexChannel++) {
        for (let index = 0; index < filteredData.length; index++) {
          if (selectedChannels[indexChannel] === filteredData[index].channel - 1) {
            if (signal.aborted) {
              throw new Error("Proceso cancelado por el usuario");
            }
            if (index % 1000 === 0 && index !== 0) {
              console.log(
                "******************************************OTRO ZIP****************************************************",
              );
              newDate2 = new Date(filteredData[index - 1].dateTime);
              downloadZip(
                zip,
                newDate1,
                newDate2,
                filteredData[index].asset,
                filteredData[index].equipment_ID,
                filteredData[index].channel,
              );
              zip = new JSZip();
              newDate1 = new Date(filteredData[index].dateTime);
            }
            const response = await getAnalysisSpatialData(
              filteredData[index].asset,
              filteredData[index].dateTime,
              filteredData[index].equipment_ID,
              filteredData[index].channel,
            );
            asset = filteredData[index].asset;
            equipmentID = filteredData[index].equipment_ID;
            channel = filteredData[index].channel;
            const data = response.data.data;
            const equipment = selectedAsset.equipments.filter(
              (e) => e.equipmentID === filteredData[index].equipment_ID,
            );
            const newItem = {};
            const channelPolyline = equipment[0].channels[filteredData[index].channel - 1];
            const measures = equipment[0].pMeasureNames;
            newItem["pk"] = points[channelPolyline].points.map((p) => p.pk);

            for (let indexMeasure = 0; indexMeasure < measures.length; indexMeasure++) {
              const chartSpatialData = {
                xAxis: {
                  data: newItem["pk"],
                },
                series: data?.[measures[indexMeasure]].map((d) => ({
                  data: d,
                })),
              };
              const csvContent = generateCSV(
                chartSpatialData,
                filteredData[index].dateTime,
                measures[indexMeasure],
                intl,
              );
              const fileName = `${filteredData[index].dateTime.replace(" ", "T")}_${measures[indexMeasure]}.csv`;
              zip.file(fileName, csvContent);
            }

            processedItems++;
            const progress = Math.round((processedItems / totalItems) * 100) * selectedChannels.length;
            updateProgress(Math.min(progress, 100));
          }
        }
        await downloadZip(zip, newDate1, endDate, asset, equipmentID, channel);
      }
      updateProgress(100);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Proceso de descarga cancelado");
      } else {
        console.error("Error durante la descarga:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const controller = new AbortController();
    startDownload(controller, selectedAsset, dateOptions[date], dateOptions[date2]);

    downloadData(controller.signal)
      .then(() => {
        console.log("Descarga completada");
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Descarga cancelada por el usuario");
        } else {
          console.error("Error durante la descarga:", error);
        }
      })
      .finally(() => {
        finishDownload();
      });
  };

  function downloadZip(zip, newDate1, newDate2, asset, equipmentID, channel) {
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      const year = newDate1.getFullYear();
      const month = ("0" + (newDate1.getMonth() + 1)).slice(-2);
      const day = ("0" + newDate1.getDate()).slice(-2);

      const formattedDate = year + "-" + month + "-" + day;

      const year2 = newDate2.getFullYear();
      const month2 = ("0" + (newDate2.getMonth() + 1)).slice(-2);
      const day2 = ("0" + newDate2.getDate()).slice(-2);

      const formattedDate2 = year2 + "-" + month2 + "-" + day2;
      a.download = `${asset}_in_${equipmentID}from_${formattedDate}_to_${formattedDate2}_channel${channel}.zip`;
      a.click();
      URL.revokeObjectURL(a.href);
    });
  }

  if (assets.loading || isNil(currentUser)) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }
  return (
    <div style={{ width: "100%" }}>
      {(loading || downloadState.isDownloading) && (
        <div
          style={{
            position: "absolute",
            width: "89%",
            height: "89%",
            backgroundColor: "rgb(176 176 176 / 60%)",
            zIndex: 1001,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1>{`${intl.formatMessage({ id: "generating.zips" })}...`}</h1>
          <Box sx={{ width: "20%", margin: "1%" }}>
            <LinearProgress
              variant="determinate"
              value={downloadState.progress}
              sx={{
                backgroundColor: palette.light,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: palette.primary,
                },
              }}
            />
          </Box>
          <Button
            sx={{ marginTop: "1%" }}
            variant="contained"
            style={{
              height: "2.5rem",
              backgroundColor: palette.primary,
              color: "white",
            }}
            size="small"
            onClick={cancelDownload}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </div>
      )}
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>
        {intl.formatMessage({ id: "asset.data.download" })}
      </div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(assets) || isEmpty(assets) ? 400 : "80vh",
          width: "100%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
          overflow: "auto",
        }}
      >
        {!isNil(selectedAsset) && (
          <div
            style={{
              position: "relative",
              margin: "2rem 0 0 1rem",
              padding: "0 1rem 1rem 1rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-2rem",
                backgroundColor: palette.likeWhite,
                padding: "0.2rem 0.4rem 0.2rem 0",
                left: "-1rem",
              }}
            >
              <TextField
                id="asset_to_configure"
                margin="dense"
                style={{ width: "10rem" }}
                defaultValue={assets?.data?.[0]?.name}
                onChange={(e) => {
                  setDisableButton(true);
                  const selectedAssetName = e.target.value;
                  const selectedAsset = assets.data.find((asset) => asset.name === selectedAssetName);
                  setSelectedAsset(selectedAsset);
                }}
                select
                label={intl.formatMessage({ id: "assets" })}
                required
              >
                {!isNil(assets.data) &&
                  assets.data.map((asset, index) => (
                    <MenuItem value={asset.name} key={asset.name + index}>
                      {asset.name}
                    </MenuItem>
                  ))}
              </TextField>
              {!isNil(selectedAsset) && (
                <TextField
                  id="equipment_to_configure"
                  margin="dense"
                  style={{ width: "10rem" }}
                  defaultValue={selectedEquipment.equipmentID}
                  value={selectedEquipment.equipmentID}
                  onChange={(e) => {
                    setDisableButton(true);
                    const selectedEquipmentID = e.target.value;
                    const selectedEquip = assets.data.find((asset) => asset.name === selectedEquipmentID);
                    setSelectedEquipment(selectedEquip);
                    const aux = [];
                    for (let index = 0; index < selectedEquip.channels.length; index++) {
                      aux.push(index);
                    }
                    setSelectedChannels(aux);
                  }}
                  select
                  label={intl.formatMessage({ id: "equipments" })}
                  required
                >
                  {!isNil(selectedAsset.equipments) &&
                    selectedAsset.equipments.map((equipment, index) => (
                      <MenuItem value={equipment.equipmentID} key={equipment.equipmentID + index}>
                        {equipment.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {!isNil(selectedEquipment) && (
                <Autocomplete
                  multiple
                  id="multiple-checkboxes"
                  options={[...selectedEquipment.channels.map((_, index) => index + 1)]}
                  disableCloseOnSelect
                  onChange={(event, values) => {
                    let newSelected = clone(selectedChannels);
                    if (event.isTrusted) {
                      const updatedValues = values.map((value) => value - 1);
                      newSelected = updatedValues;
                    }
                    setSelectedChannels(newSelected);
                    loadDateOptions({
                      asset: selectedAsset.name,
                      selectedChannels: newSelected,
                      setDateOptions,
                      setDate,
                      setDateTimes,
                      setDate2,
                    });
                  }}
                  getOptionLabel={(option) => selectedEquipment.channelsNames[option - 1]}
                  value={[...selectedChannels.map((channel) => channel + 1)]}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selectedChannels.includes(option - 1)} />
                        {selectedEquipment.channelsNames[option - 1]}
                      </li>
                    );
                  }}
                  style={{ width: "21rem", margin: "2% 0 2% 0" }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={intl.formatMessage({ id: "select.channels" })} />
                  )}
                  ref={autocompleteRef}
                />
              )}

              {!isEmpty(dateOptions) && (
                <div style={{ marginTop: "1%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="date_picker"
                      sx={{ paddingRight: "1%" }}
                      label={intl.formatMessage({ id: "date.start" })}
                      shouldDisableDate={shouldDisableDate}
                      format="dd-MM-yyyy"
                      value={parseISO(dateOptions[date])}
                      onChange={(e) => {
                        //setLoading(true);
                        setDate(null);
                        const newDate1 = new Date(formatDate(e));
                        const newDate2 = new Date(dateOptions[date2]);

                        const year = newDate1.getFullYear();
                        const month = ("0" + (newDate1.getMonth() + 1)).slice(-2);
                        const day = ("0" + newDate1.getDate()).slice(-2);

                        const formattedDate = year + "-" + month + "-" + day;
                        if (newDate1 <= newDate2) {
                          setDate(dateOptions.indexOf(formattedDate));
                        } else {
                          setTimeout(() => {
                            setDate(date);
                          }, 0);
                          toast.warning(`${intl.formatMessage({ id: "choose.dates" })}`, {
                            className: "toast-warning",
                          });
                        }
                      }}
                    />
                    <DatePicker
                      id="date_picker2"
                      label={intl.formatMessage({ id: "date.end" })}
                      shouldDisableDate={shouldDisableDate}
                      format="dd-MM-yyyy"
                      value={parseISO(dateOptions[date2])}
                      onChange={(e) => {
                        //setLoading(true);
                        setDate2(null);
                        const newDate1 = new Date(dateOptions[date]);
                        const newDate2 = new Date(formatDate(e));
                        const year = newDate2.getFullYear();
                        const month = ("0" + (newDate2.getMonth() + 1)).slice(-2);
                        const day = ("0" + newDate2.getDate()).slice(-2);

                        const formattedDate = year + "-" + month + "-" + day;
                        if (newDate1 <= newDate2) {
                          setDate2(dateOptions.indexOf(formattedDate));
                        } else {
                          setTimeout(() => {
                            setDate2(date2);
                          }, 0);
                          toast.warning(`${intl.formatMessage({ id: "choose.dates" })}`, {
                            className: "toast-warning",
                          });
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <Button
                    sx={{ marginTop: "1%" }}
                    variant="contained"
                    style={{
                      height: "2.5rem",
                      backgroundColor: disableButton || isEmpty(selectedChannels) ? palette.light : palette.primary,
                      color: "white",
                    }}
                    size="small"
                    onClick={async () => {
                      handleDownload();
                    }}
                    disabled={disableButton || isEmpty(selectedChannels)}
                  >
                    {intl.formatMessage({ id: "download" })}
                  </Button>
                  {/*disableButton && (
                    <Alert severity="warning">{intl.formatMessage({ id: "10.days.difference" })}</Alert>
                  )*/}
                </div>
              )}
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Download;
