import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getUserData, getUserRoleData } from "../api";
import { setAuthAction } from "./authSlice";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "USER",
  initialState,
  reducers: {
    getUserDataRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getUserDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getUserDataFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getUserDataRequest, getUserDataSuccess, getUserDataFailure } = userSlice.actions;

export const getUserDataAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getUserDataRequest());
      let response = await getUserData(id);
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;

        response = await getUserRoleData();
        if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
          payload = { ...payload, options: response.data.data?.[0]?.options };
          dispatch(getUserDataSuccess(payload));
          return;
        }
      }
      dispatch(getUserDataFailure("Invalid response"));
    } catch (error) {
      dispatch(getUserDataFailure(error));
      dispatch(setAuthAction(null));
    }
  };
};

export const clearUserDataAction = () => {
  return async (dispatch) => {
    dispatch(getUserDataSuccess(null));
  };
};

export default userSlice.reducer;
