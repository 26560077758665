import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { handleChange, handleMenuDataUseEffect } from "./utils/utils";

const RolePermissions = ({ usedValues, setUsedValues, readOnly }) => {
  const intl = useIntl();
  const menuData = useSelector((state) => state.menuData.data);

  const [checkedList, setCheckedList] = useState();

  useEffect(() => {
    handleMenuDataUseEffect({ menuData, setCheckedList, usedValues, intl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  return (
    <div>
      {!isNil(checkedList) &&
        checkedList.map((checked) => {
          return isNil(checked.children) ? (
            <div key={checked.value}>
              <FormControlLabel
                label={checked.text}
                control={
                  <Checkbox
                    checked={checked.selected}
                    disabled={readOnly}
                    onChange={() => handleChange({ checked, checkedList, setCheckedList, setUsedValues })}
                  />
                }
              />
            </div>
          ) : (
            <div key={checked.value}>
              <FormControlLabel
                label={checked.text}
                control={
                  <Checkbox
                    checked={checked.selected}
                    disabled={readOnly}
                    indeterminate={checked.indeterminated}
                    onChange={() => handleChange({ checked, checkedList, setCheckedList, setUsedValues })}
                  />
                }
              />
              <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                {checked.children.map((child) => {
                  return (
                    <FormControlLabel
                      key={child.value}
                      label={child.text}
                      control={
                        <Checkbox
                          checked={child.selected}
                          disabled={readOnly}
                          onChange={() =>
                            handleChange({
                              checked: child,
                              parent: checked,
                              checkedList,
                              setCheckedList,
                              setUsedValues,
                            })
                          }
                        />
                      }
                    />
                  );
                })}
              </Box>
            </div>
          );
        })}
    </div>
  );
};

export default RolePermissions;
