import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import messages, { languagesItems } from "../../../config/locales";
import { setLocaleDataAction } from "../../redux/configSlice";
import Selector from "../Selector";
import palette from "../../../config/colorPalette";
import { SelectorContainer } from "./styled";

const IntlProviderWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => {
    const languages = [];
    for (let item in messages) {
      languages.push(item);
    }
    if (languages.includes(state.configData.locale)) {
      return state.configData.locale;
    } else {
      return "en";
    }
  });
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <SelectorContainer>
        <Selector
          items={languagesItems}
          onChange={(value) => {
            dispatch(setLocaleDataAction(value));
          }}
          color={palette.dark}
        />
      </SelectorContainer>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
