import { clone, isNil } from "ramda";
import { interpolateRgb } from "d3";

export const shouldAddPoints = ({
  indexes,
  index,
  pk,
  thresholds,
  difference,
  value,
  prevValues,
  filteredPoints,
  temporalIndexes,
  visualIndexes,
  startIndex,
  endIndex,
}) => {
  let alertType = getAlertType([value], [thresholds]); // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high
  if (
    indexes.includes(index) ||
    prevValues?.state !== alertType ||
    Math.abs(value - prevValues?.values?.[0]) > difference ||
    temporalIndexes.includes(pk) ||
    visualIndexes.includes(index)
  ) {
    prevValues.values = [value];
    prevValues.state = alertType;
    if (index >= startIndex && index < endIndex) {
      prevValues.withColor = true;
    } else {
      prevValues.withColor = false;
    }
    if (index >= startIndex && index <= endIndex && startIndex === endIndex) {
      prevValues.withColor = true;
    }
    return true;
  } else {
    filteredPoints[filteredPoints.length - 1].measuresValue = Math.max(value, prevValues?.values?.[0]);
    filteredPoints[filteredPoints.length - 1].alertType = alertType;
  }
  return false;
};

export const shouldAddPointsTotal = ({
  indexes,
  index,
  pk,
  thresholds,
  difference,
  value,
  prevValues,
  filteredPoints,
  temporalIndexes,
  visualIndexes,
}) => {
  let alertType = getAlertType([value], [thresholds]); // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high
  if (
    indexes.includes(index) ||
    prevValues?.state !== alertType ||
    Math.abs(value - prevValues?.values?.[0]) > difference ||
    temporalIndexes.includes(pk) ||
    visualIndexes.includes(index)
  ) {
    prevValues.values = [value];
    prevValues.state = alertType;
    if (visualIndexes.includes(index) || temporalIndexes.includes(pk)) {
      prevValues.withColor = true;
    } else {
      prevValues.withColor = false;
    }
    return true;
  } else {
    filteredPoints[filteredPoints.length - 1].measuresValue = Math.max(value, prevValues?.values?.[0]);
    filteredPoints[filteredPoints.length - 1].alertType = alertType;
  }
  return false;
};

export const getAlertType = (values, thresholds) => {
  let alertType = 0; // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high
  for (let i = 0; i < values.length && alertType < 3; i++) {
    const item = values[i];
    for (let j = thresholds[i].length - 1, type = 3; j >= 0 && alertType < 3; j--, type--) {
      const th = thresholds[i][j];
      if (th <= item && alertType < type) {
        alertType = type;
      }
    }
  }
  return alertType;
};
export const getMeasure = (measures, min, max, thresholds, domain) => {
  let result = 0;

  const minValue = min;
  const maxValue = max;
  const range = maxValue - minValue;
  const th = clone(thresholds);

  while (th.length < domain.length) {
    if (!isNil(th[th.length - 1])) {
      th.push(th[th.length - 1]);
    } else {
      th.push(Infinity);
    }
  }
  for (let j = domain.length - 1; j >= 0; j--) {
    if (th?.[j] <= measures) {
      if (result < domain[j]) {
        result = domain[j];
      }
      if (j === domain.length - 1) {
        return result;
      }
    }
  }
  if (measures < th?.[0]) {
    let temp = (measures - minValue) / range;
    temp = temp < 0 ? Math.abs(temp) : temp;
    if (temp > 1 || temp < -1) {
      temp = 1;
    }
    if (result < temp) {
      result = temp;
    }
  }
  return result;
};

export const createColor = ({ assetData, measureName, percentiles, scales, equipmentData, intl }) => {
  if (!isNil(equipmentData)) {
    let colorSteps = 0;
    const measure_index = equipmentData.pMeasureNames?.indexOf(measureName);
    const threshold = equipmentData.pThresholds?.[measure_index];
    let positionsLetters = ["10%"];
    let positionsColors = [];
    let domain = [];
    let range = [];
    let max = 1;
    let min = 0;
    let bounds;
    if (!isNil(percentiles) && !isNil(percentiles[measureName])) {
      max = percentiles[measureName][1];
      min = percentiles[measureName][0];
      const diff = max - min;
      if (diff <= 3) {
        bounds = [
          Math.round(min * 10) / 10,
          Math.round((min + diff / 4) * 10) / 10,
          Math.round((min + diff / 2) * 10) / 10,
          Math.round((max - diff / 4) * 10) / 10,
          Math.round(max * 10) / 10,
        ];
      } else {
        bounds = [
          Math.round(min),
          Math.round(min + diff / 4),
          Math.round(min + diff / 2),
          Math.round(max - diff / 4),
          Math.round(max),
        ];
      }

      if (threshold.length < 3 && measureName !== "temperature" && measureName !== "strain") {
        bounds.splice(0, threshold.length === 2 ? 1 : 2);
      }
      domain = [0, 0.2, 0.5, 0.8];
      if (measureName === "temperature" || measureName === "strain") {
        positionsLetters.push("32%");
        positionsLetters.push("52%");
        positionsLetters.push("72%");
        positionsLetters.push("92%");
        positionsColors = ["0%", "20%", "30%", "40%", "50%", "70%", "80%", "90%"];
      }
    } else {
      bounds = [intl.formatMessage({ id: "Alert" })];

      //if (threshold.length < 3) {
      //  bounds.splice(0, threshold.length === 2 ? 1 : 2);
      //}
      bounds.unshift("OK");
      positionsLetters.push("95%");
      domain = [0, 0.55];
      range = [interpolateRgb("green")(0), interpolateRgb("red")(0.55)];
      positionsColors = ["10%", "90%"];
    }

    colorSteps = positionsColors.length;
    return { colorSteps, range, positionsLetters, positionsColors, domain, min, max, threshold, bounds };
  } else {
    return {
      colorSteps: null,
      range: null,
      positionsLetters: null,
      positionsColors: null,
      domain: null,
      min: null,
      max: null,
      threshold: null,
      bounds: null,
    };
  }
};
