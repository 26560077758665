import styled from "styled-components";

export const SelectorContainer = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
