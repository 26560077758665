import { getAssetsDatetimes } from "../../api";
import { isEmpty, isNil } from "ramda";

export const loadDateOptions = async ({
  date,
  time,
  asset,
  selectedChannels,
  setDateOptions = () => {},
  setTimeOptions = () => {},
  setDate = () => {},
  setTime = () => {},
  setDateTimes = () => {},
  setDate2 = () => {},
  setTime2 = () => {},
  setLoading = () => {},
}) => {
  setLoading(true);
  const response = await getAssetsDatetimes([asset]);
  if (!isNil(response?.status) && response.status === 200 && !isNil(response.data) && !isEmpty(response.data.data)) {
    const noFilterData = response.data.data;
    const data = noFilterData.filter((filterDate) => selectedChannels.includes(filterDate.channel - 1));
    let dates = new Set();
    let times = new Set();

    data.sort((a, b) => {
      const dA = new Date(a.dateTime);
      const dB = new Date(b.dateTime);
      return dB - dA;
    });

    let localDateIndex = null;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.asset === asset) {
        const temp = item.dateTime.indexOf("T") !== -1 ? item.dateTime.split("T") : item.dateTime.split(" ");
        dates.add(temp[0]);
        if (isNil(date)) {
          date = temp[0];
        }
        if (temp[0] === date) {
          localDateIndex = isNil(localDateIndex) ? Array.from(dates).indexOf(date) : localDateIndex;
          times.add(temp[1]);
        }
      }
    }
    dates = Array.from(dates).reverse();
    times = Array.from(times).reverse();
    const localTimeIndex = isNil(time) ? 0 : times.indexOf(time);
    setDateTimes(data.reverse());
    setDate(0);
    setDate2(dates.length - 1);
    setTime(localTimeIndex);
    setDateOptions(dates);
    setTimeOptions(times);
    setLoading(false);
  } else {
    setDateOptions(null);
    setTimeOptions(null);
    setDate(null);
    setTime(null);
    setLoading(false);
  }
};
export const generateCSV = (data, dateTime, measureName, intl) => {
  const header = ["PK", intl.formatMessage({ id: measureName })].join(",");
  const pkData = data?.xAxis?.data;
  const serialData = data?.series;
  const combinedData = pkData?.map((pk, index) => [pk, serialData[index].data]);

  const csvData = [header].concat(combinedData.map((row) => row.join(","))).join("\n");

  return csvData;
};
