import React, { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Avatar,
  Input,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import avatar from "../../../../../images/avatar.png";
import palette from "../../../../../config/colorPalette";
import { getImageSrc, getMuiTheme, validatePassword, validateSecondPassword } from "../../../../utils/utils";
import { AvatarUploadBtn, ErrorText } from "./styled";
import {
  handleChanges,
  handleClose,
  handleModalAccept,
  uploadImage,
  userAvatarHandler,
  userNameHandler,
} from "./utils/utils";

const UserProfileModal = ({ open, setOpen, user, firstTime, setFirstTime }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const inputRef = useRef();
  const theme = useMemo(() => getMuiTheme(), []);

  const [localUser, setLocalUser] = useState(user);
  const [userFirstData] = useState(user);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [secondPasswordError, setSecondPasswordError] = useState();
  const [oldPasswordError, setOldPasswordError] = useState();
  const [showOldPassword, setShowOldPassword] = useState(false);

  const disabled = useMemo(
    () =>
      userAvatarHandler(localUser, userFirstData) &&
      userNameHandler(localUser, userFirstData) &&
      (!validatePassword(oldPassword) || !validateSecondPassword(password, secondPassword)),
    [localUser, userFirstData, oldPassword, password, secondPassword],
  );

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          data-testid="user-modal"
          style={{ position: "fixed", zIndex: 10001 }}
          open={open}
          onClose={() => {
            if (!firstTime) {
              handleClose(setLocalUser, setOpen);
            }
          }}
        >
          <DialogTitle style={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>
            {intl.formatMessage({ id: "edit profile" })}
          </DialogTitle>
          <DialogContent data-testid="user-modal-content" style={{ paddingBottom: "0" }}>
            <form autoComplete="off">
              <div>
                <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
                <div style={{ display: "flex" }}>
                  <div></div>
                  <div
                    style={{
                      margin: "0 1rem",
                      minWidth: "15rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}
                    >
                      <Avatar
                        alt="avatar"
                        src={localUser?.avatar ? getImageSrc(localUser.avatar) : avatar}
                        sx={{ width: 150, height: 150 }}
                      />
                      <Input
                        ref={inputRef}
                        data-testid="user-avatar"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        id="avatar-input"
                        onChange={(event) => uploadImage(event, localUser, setLocalUser)}
                      />
                      <AvatarUploadBtn style={{ color: palette.dark, backgroundColor: `${palette.primary}70` }}>
                        <label htmlFor="avatar-input">
                          <Button variant="standard" component="span" onClick={() => inputRef.current.click()}>
                            <PhotoCameraIcon />
                          </Button>
                        </label>
                      </AvatarUploadBtn>
                    </div>
                    <TextField
                      autoFocus
                      variant="outlined"
                      autoComplete="off"
                      margin="dense"
                      id="name"
                      label={intl.formatMessage({ id: "name" })}
                      type="text"
                      fullWidth
                      value={localUser?.name ?? ""}
                      onChange={(event) => {
                        setLocalUser({ ...localUser, name: event.target.value });
                      }}
                    />
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="currentPassword">
                        {intl.formatMessage({ id: "current password" })}*
                      </InputLabel>
                      <OutlinedInput
                        id="currentPassword"
                        type={showOldPassword ? "text" : "password"}
                        autoComplete="new-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="visibility"
                              onClick={() => setShowOldPassword(!showOldPassword)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                              data-testid={`user-profile-visibility`}
                            >
                              {showOldPassword ? <VisibilityOff color="#e4e6ef" /> : <Visibility color="#e4e6ef" />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={`${intl.formatMessage({ id: "current password" })}*`}
                        value={oldPassword ?? ""}
                        onChange={(event) =>
                          handleChanges({
                            who: "password",
                            value: event.target.value,
                            setError: setOldPasswordError,
                            setData: setOldPassword,
                            intl,
                          })
                        }
                        error={oldPasswordError ? true : false}
                        data-testid={`user-profile-old-password`}
                      />
                      {!isNil(oldPasswordError) && (
                        <ErrorText>{intl.formatMessage({ id: oldPasswordError })}</ErrorText>
                      )}
                    </FormControl>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="password">{intl.formatMessage({ id: "new password" })}*</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                              data-testid={`user-profile-visibility`}
                            >
                              {showPassword ? <VisibilityOff color="#e4e6ef" /> : <Visibility color="#e4e6ef" />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={`${intl.formatMessage({ id: "new password" })}*`}
                        value={password ?? ""}
                        onChange={(event) =>
                          handleChanges({
                            who: "password",
                            value: event.target.value,
                            setError: setPasswordError,
                            setData: setPassword,
                            intl,
                          })
                        }
                        disabled={isNil(oldPassword) || isEmpty(oldPassword)}
                        error={passwordError ? true : false}
                        data-testid={`user-profile-new-password`}
                      />
                      {!isNil(passwordError) && <ErrorText>{intl.formatMessage({ id: passwordError })}</ErrorText>}
                    </FormControl>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="secondPassword">{intl.formatMessage({ id: "repeat password" })}*</InputLabel>
                      <OutlinedInput
                        id="secondPassword"
                        type={showSecondPassword ? "text" : "password"}
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="visibility"
                              onClick={() => setShowSecondPassword(!showSecondPassword)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                              data-testid={`user-profile-visibility`}
                            >
                              {showSecondPassword ? <VisibilityOff color="#e4e6ef" /> : <Visibility color="#e4e6ef" />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={`${intl.formatMessage({ id: "repeat password" })}*`}
                        value={secondPassword ?? ""}
                        onChange={(event) =>
                          handleChanges({
                            who: "secondPassword",
                            value: event.target.value,
                            setData: setSecondPassword,
                            setError: setSecondPasswordError,
                            password,
                            intl,
                          })
                        }
                        disabled={isNil(oldPassword) || isEmpty(oldPassword)}
                        error={secondPasswordError ? true : false}
                        data-testid={`user-profile-password`}
                      />
                      {!isNil(secondPasswordError) && (
                        <ErrorText>{intl.formatMessage({ id: secondPasswordError })}</ErrorText>
                      )}
                    </FormControl>
                  </div>
                </div>
                <Divider style={{ marginTop: "1rem" }} />
              </div>
            </form>
          </DialogContent>
          <DialogActions data-testid="user-modal-actions">
            <Button
              variant="contained"
              style={{ backgroundColor: firstTime ? palette.light : palette.secondary, color: !firstTime ?? "white" }}
              onClick={() => handleClose(setLocalUser, setOpen)}
              data-testid="user-modal-cancel-btn"
              disabled={firstTime}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: disabled ? palette.light : palette.primary,
              }}
              disabled={disabled}
              onClick={async () => {
                const result = await handleModalAccept({
                  userFirstData,
                  data: {
                    ...localUser,
                    order: localUser.order ?? 0,
                    password: !isEmpty(password) ? password : null,
                    firstTime: false,
                  },
                  oldPassword,
                  setError: setOldPasswordError,
                  setFirstTime,
                  dispatch,
                  navigate,
                  intl,
                });
                if (result) {
                  handleClose(setLocalUser, setOpen);
                }
              }}
              data-testid="user-modal-accept-btn"
            >
              {intl.formatMessage({ id: "accept" })}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default UserProfileModal;
