import { createSlice } from "@reduxjs/toolkit";

const initialState = { isAuthenticated: null };

const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setAuthAction: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthAction } = authSlice.actions;
export default authSlice.reducer;
