import styled from "styled-components";

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 20vh;
  width: 100%;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const LegendColor = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const LegendValue = styled.span`
  font-size: 14px;
`;
