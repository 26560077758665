import { isEmpty, isNil } from "ramda";

import { putUser, verifyOldPassword } from "../../../../../api";
import { getUserDataAction } from "../../../../../redux/userSlice";
import { validatePassword, verifyRequestError } from "../../../../../utils/utils";

export const handleClose = (setLocalUser, setOpen) => {
  setLocalUser(null);
  setOpen(false);
};

export const handleModalAccept = async ({
  userFirstData,
  data,
  oldPassword,
  setError,
  setFirstTime,
  dispatch,
  navigate,
  intl,
}) => {
  if (isNil(data)) {
    return false;
  }
  try {
    if (!isNil(oldPassword) && !isEmpty(oldPassword)) {
      if (validatePassword(oldPassword)) {
        const verifyResponse = await verifyOldPassword(oldPassword);
        if (isNil(verifyResponse?.status) || verifyResponse.status !== 200) {
          if (!isNil(verifyResponse?.response?.data?.detail)) {
            verifyRequestError({ error: verifyResponse.response.data.detail, dispatch, navigate, intl });
          }
          throw Error(intl.formatMessage({ id: "old.password.verification.error.message" }));
        }
      } else {
        throw Error(intl.formatMessage({ id: "old.password.verification.error.message" }));
      }
    } else if (userFirstData.name === data.name && userFirstData.avatar === data.avatar) {
      throw Error(intl.formatMessage({ id: "data.without.changes.error.message" }));
    }
    let response = await putUser(data.id, data, intl);
    setFirstTime(false);
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      dispatch(getUserDataAction(data.id));
      return true;
    }
  } catch (error) {
    setError("old.password.verification.error.message");
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  return false;
};

export const handleChanges = ({ who, value, setError, setData, password, intl }) => {
  if (who === "password") {
    const isValid = validatePassword(value);
    setError(!isValid ? "password.verification.error.message" : null);
  }
  if (who === "secondPassword") {
    const isValid = validatePassword(value);
    setError(
      !isValid
        ? "password.verification.error.message"
        : value !== password
        ? "passwords.comparison.verification.error.message"
        : null,
    );
  }
  setData(value);
};

export const userNameHandler = (prevUser, currentUser) => {
  return prevUser?.name === currentUser?.name;
};
export const userAvatarHandler = (prevUser, currentUser) => {
  return prevUser?.avatar === currentUser?.avatar;
};

export const uploadImage = (event, localUser, setLocalUser) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      setLocalUser({ ...localUser, avatar: base64String.split("base64,")?.[1] });
    };
    reader.readAsDataURL(file);
  }
};
