import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, isNil } from "ramda";
import { useIntl } from "react-intl";
import { FormControl, IconButton, MenuItem, ThemeProvider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ErrorText } from "./styled";
import { getOptions } from "../../utils/utils";
import { getMuiTheme } from "../../../../utils/utils";
import palette from "../../../../../config/colorPalette";
import { TextField } from "@mui/material";
import { onBlurHandler, onRemoveHandler, onSelectChangeHandler, onValueChangeHandler } from "./utils";

const ThresholdsValues = ({
  measureIndex,
  thresholdTypes,
  errors,
  setErrors,
  equipment,
  thresholdsValues,
  setThresholdsValues,
  equipmentIndex,
  channelIndex,
  zoneIndex,
}) => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);
  const options = useMemo(() => getOptions(intl), [intl]);
  const [tuples, setTuples] = useState([]);

  useEffect(() => {
    setTuples(thresholdsValues[equipment][measureIndex][channelIndex][zoneIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdsValues]);

  if (isNil(tuples)) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ flex: "1", display: "grid" }}>
        {!isNil(tuples) &&
          tuples.map((item, index) => (
            <FormControl>
              <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <TextField
                  id="type"
                  margin="dense"
                  style={{ width: "10rem" }}
                  variant="outlined"
                  value={item.type}
                  onChange={(e) =>
                    onSelectChangeHandler({
                      value: e.target.value,
                      index,
                      tuples,
                      setTuples,
                      idErrorPrefix: `threshold-${measureIndex}-select`,
                      errors,
                      setErrors,
                      intl,
                    })
                  }
                  select
                  label={intl.formatMessage({ id: "Threshold type" })}
                  error={errors[`threshold-${measureIndex}-select-${index}`] ? true : false}
                  required
                >
                  <MenuItem value={null}>{intl.formatMessage({ id: "none" })}</MenuItem>
                  {options[index].map((opt) => (
                    <MenuItem value={opt}>{thresholdTypes[opt]}</MenuItem>
                  ))}
                </TextField>
                {!isNil(item.type) && (
                  <>
                    <TextField
                      type="number"
                      label={intl.formatMessage({ id: "Threshold value" })}
                      inputProps={{ step: "0.1" }}
                      value={item.value}
                      style={{ marginTop: "0.2rem" }}
                      onChange={(e) =>
                        onValueChangeHandler({
                          value: !isEmpty(e.target.value) ? parseFloat(e.target.value) : null,
                          index,
                          tuples,
                          setTuples,
                        })
                      }
                      error={errors[`threshold-${measureIndex}-value-${index}`] ? true : false}
                      onBlur={() => {
                        onBlurHandler({
                          idErrorPrefix: `threshold-${measureIndex}-value`,
                          index,
                          tuples,
                          errors,
                          setErrors,
                          thresholdTypes,
                          equipment,
                          measureIndex,
                          thresholdsValues,
                          setThresholdsValues,
                          channelIndex,
                          zoneIndex,
                          intl,
                        });
                      }}
                      required
                    />
                    {index !== 0 && (
                      <DeleteForeverIcon
                        onClick={() => onRemoveHandler({ index, tuples, setTuples })}
                        sx={{ color: palette.primary, cursor: "pointer" }}
                      />
                    )}
                  </>
                )}
              </div>
              {!isNil(errors[`threshold-${measureIndex}-value-${index}`]) && (
                <ErrorText style={{ margin: "0.2rem" }}>
                  {intl.formatMessage({ id: errors[`threshold-${measureIndex}-value-${index}`] })}
                </ErrorText>
              )}
              {!isNil(errors[`threshold-${measureIndex}-select-${index}`]) && (
                <ErrorText style={{ margin: "0.2rem" }}>
                  {intl.formatMessage({ id: errors[`threshold-${measureIndex}-select-${index}`] })}
                </ErrorText>
              )}
            </FormControl>
          ))}
        {tuples?.length < thresholdTypes.length && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{ backgroundColor: palette.dividerLight }}
              onClick={() => {
                setTuples([...tuples, { type: null, value: null }]);
              }}
            >
              <AddIcon sx={{ color: palette.primary }} />
            </IconButton>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: palette.primary,
                margin: "8px 0",
              }}
            ></div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ThresholdsValues;
