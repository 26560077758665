import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getConfigData } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
  locale: navigator.language.split(/[-_]/)?.[0],
};

const configSlice = createSlice({
  name: "CONFIG",
  initialState,
  reducers: {
    setLocaleDataAction: (state, action) => {
      state.locale = action.payload;
    },
    getConfigDataRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getConfigDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getConfigDataFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getConfigDataRequest, getConfigDataSuccess, getConfigDataFailure, setLocaleDataAction } =
  configSlice.actions;

export const getConfigDataAction = () => {
  return async (dispatch) => {
    try {
      dispatch(getConfigDataRequest());
      let response = await getConfigData();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;
        dispatch(getConfigDataSuccess(payload));
        return;
      }
      dispatch(getConfigDataFailure("Invalid response"));
    } catch (error) {
      dispatch(getConfigDataFailure(error));
    }
  };
};

export const clearConfigDataAction = () => {
  return async (dispatch) => {
    dispatch(getConfigDataSuccess(null));
  };
};

export default configSlice.reducer;
