import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getMenuData } from "../api";

const initialState = {
  selectedOption: 1,
  data: null,
  loading: false,
  error: null,
};

const menuSlice = createSlice({
  name: "MENU",
  initialState,
  reducers: {
    setSelectedMenuOptionAction: (state, action) => {
      state.selectedOption = action.payload;
    },
    getMenuDataRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getMenuDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getMenuDataFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getMenuDataRequest, getMenuDataSuccess, getMenuDataFailure, setSelectedMenuOptionAction } =
  menuSlice.actions;

export const getMenuDataAction = () => {
  return async (dispatch) => {
    try {
      dispatch(getMenuDataRequest());
      let response = await getMenuData();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        let payload = response.data.data;
        dispatch(getMenuDataSuccess(payload));
        return;
      }
      dispatch(getMenuDataFailure("Invalid response"));
    } catch (error) {
      dispatch(getMenuDataFailure(error));
    }
  };
};

export const clearMenuDataAction = () => {
  return async (dispatch) => {
    dispatch(setSelectedMenuOptionAction(null));
    dispatch(getMenuDataSuccess(null));
  };
};

export default menuSlice.reducer;
