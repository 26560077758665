import { clone, isNil } from "ramda";

const isParentChecked = (checked) => {
  let result = true;
  if (!isNil(checked.children)) {
    checked.children.forEach((child) => {
      if (!child.selected) {
        result = false;
        return;
      }
    });
    return result;
  }
  return false;
};

const isIndeterminated = (checked) => {
  let result = false;
  if (!isNil(checked.children)) {
    checked.children.forEach((child) => {
      if (child.selected) {
        result = true;
        return;
      }
    });
  }
  return result;
};

export const handleChange = ({ checked, parent = null, checkedList, setCheckedList, setUsedValues }) => {
  const usedElements = [];
  const list = clone(checkedList);
  if (isNil(parent)) {
    list.forEach((element) => {
      if (element.value === checked.value) {
        element.selected = !element.selected;
        if (!isNil(element.children)) {
          element.indeterminated = false;
          element.children.forEach((child) => {
            child.selected = element.selected;
          });
        }
      }
    });
  } else {
    const element = list.find((item) => item.value === parent.value);
    element.children.forEach((child) => {
      if (child.value === checked.value) {
        child.selected = !child.selected;
      }
    });
    element.selected = isParentChecked(element);
    element.indeterminated = !element.selected && isIndeterminated(element);
  }
  setCheckedList(list);

  list.forEach((element) => {
    if (element.selected) {
      usedElements.push(element.value);
    }
    if (!isNil(element.children)) {
      element.children.forEach((child) => {
        if (child.selected) {
          usedElements.push(child.value);
          if (!usedElements.includes(element.value)) {
            usedElements.push(element.value);
          }
        }
      });
    }
  });
  setUsedValues(usedElements);
};

export const handleMenuDataUseEffect = ({ menuData, setCheckedList, usedValues, intl }) => {
  if (!isNil(menuData)) {
    let options = clone(menuData);
    const used = [];
    const permissions = [];
    options.forEach((item) => {
      if (!used.includes(item.id)) {
        const result = {
          text: intl.formatMessage({ id: item.label }),
          value: item.value,
          selected: usedValues.includes(item.value),
        };
        if (!isNil(item.options)) {
          result["children"] = [];
          item.options.forEach((id) => {
            const opt = options.find((m) => m.id === id);
            used.push(id);
            if (!isNil(opt)) {
              result.children.push({
                text: intl.formatMessage({ id: opt.label }),
                value: opt.value,
                selected: usedValues.includes(opt.value),
              });
            }
          });
          result.selected = isParentChecked(result);
          result["indeterminated"] = !result.selected && isIndeterminated(result);
        }
        permissions.push(result);
      }
    });
    setCheckedList(permissions);
  }
};
