import React from "react";
import { LegendContainer } from "./styled";
import { createColor } from "../MapAnalysis/utils";

const Legend = ({ assetData, equipmentData, measureName, percentiles, scales, intl }) => {
  const { colorSteps, positionsLetters, positionsColors, bounds } = createColor({
    assetData,
    measureName,
    percentiles,
    equipmentData,
    intl,
  });

  const legendItems = Array.from({ length: colorSteps }, (_, index) => {
    const value = index * (1 / colorSteps);

    const interpolation = scales[measureName];
    const color = interpolation(value);
    return { value, color };
  });

  return (
    <LegendContainer key={`${measureName}_legend`}>
      <svg width="100%" height={"11.8rem"}>
        <defs>
          <linearGradient id={`mapGradient_${measureName}`} x1="0%" y1="0%" x2="0%" y2="100%">
            {legendItems.map((item, index) => (
              <stop key={`offset_${measureName}_${index}`} offset={positionsColors[index]} stopColor={item.color} />
            ))}
          </linearGradient>
        </defs>
        <rect fill={`url(#mapGradient_${measureName})`} x="0" y="10" rx="20" ry="20" width="30%" height={"18vh"} />
        {bounds.map((bound, index) => (
          <text
            key={`text_${measureName}_${bound}_${index}`}
            x="35%"
            y={positionsLetters[index]}
            alignmentBaseline="start"
            textAnchor="start"
            fill="black"
          >
            {bound}
          </text>
        ))}
      </svg>
    </LegendContainer>
  );
};

export default Legend;
