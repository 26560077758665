import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { ThemeProvider } from "@mui/material/styles";

import { getMuiTheme } from "../../utils/utils";
import palette from "../../../config/colorPalette";

const TextModal = ({ open, setOpen, title, text, acceptFunction }) => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={handleClose} data-testid="dialog">
          <DialogTitle style={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>{title}</DialogTitle>
          <DialogContent style={{ paddingBottom: "0" }}>
            <div>
              <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
              <DialogContentText id="text-dialog">{text}</DialogContentText>
              <Divider style={{ marginTop: "1rem" }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              data-testid="dialog-cancel"
              style={{ backgroundColor: palette.secondary, color: "white" }}
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              variant="contained"
              data-testid="dialog-accept"
              style={{ backgroundColor: palette.primary, color: "white" }}
              onClick={() => acceptFunction()}
            >
              {intl.formatMessage({ id: "accept" })}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default TextModal;
