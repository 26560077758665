import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { clone, isEmpty, isNil } from "ramda";
import palette from "../../../config/colorPalette";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoIcon from "@mui/icons-material/Photo";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { getDocumentsDataAction } from "../../redux/documentationSlice";

import { deleteDocument, getDocumentsFile, postDocuments } from "../../api";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.js";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import CircularProgress from "@mui/material/CircularProgress";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

const Documentation = () => {
  const dispatch = useDispatch();

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    Open: () => <></>,
  });
  const renderToolbar = (Toolbar) => {
    return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar,
    sidebarTabs: () => [], // Esto deshabilita la barra lateral
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  const fileTypes = {
    image: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "tiff"],
    video: ["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm", "mpeg"],
    sound: ["mp3", "wav", "ogg", "flac", "aac", "m4a", "wma"],
    compressed: ["zip", "rar", "7z", "tar", "gz", "bz2"],
  };

  const intl = useIntl();
  const assets = useSelector((state) => state.assetsData);
  const currentConfig = useSelector((state) => state.configData);
  const currentUser = useSelector((state) => state.userData.data);
  const documentData = useSelector((state) => state.documentData.data);

  const [documents, setDocuments] = useState([]);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedEquipmentID, setSelectedEquipmentID] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [assetDocs, setAssetDocs] = useState([]);
  const [assetDocNames, setAssetDocNames] = useState([]);
  const [assetDocFiles, setAssetDocFiles] = useState([]);
  const [assetDocLoading, setAssetDocLoading] = useState([]);
  const [equipmentDocs, setEquipmentDocs] = useState([]);
  const [equipmentDocNames, setEquipmentDocNames] = useState([]);
  const [equipmentDocFiles, setEquipmentDocFiles] = useState([]);
  const [equipmentDocLoading, setEquipmentDocLoading] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");

  useMemo(() => {
    if (!isNil(assets.data) && !isEmpty(assets.data) && !isNil(currentConfig.data)) {
      setSelectedAsset(assets.data[0]);
      setEquipments(assets.data[0].equipments ?? []);
      setSelectedEquipment(assets.data[0].equipments[0]);
      setSelectedEquipmentID(assets.data[0].equipments[0].equipmentID);
      //setAssetDocs(assets.data[0].documentationNames);
    }
  }, [assets, currentConfig]);

  useEffect(() => {
    async function getAssetFiles() {
      if (!isNil(selectedAsset) && !isNil(documentData)) {
        //const response = await getDocuments(selectedAsset);
        const allDocumentsByAsset = documentData.filter((doc) => doc.asset === selectedAsset.name);
        setDocuments(allDocumentsByAsset);
        console.log(assetDocFiles, assetDocNames, assetDocs);
      }
    }
    getAssetFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, documentData]);
  useEffect(() => {
    if (!isEmpty(documents) && !isNil(selectedAsset)) {
      const selectedDocs = documents.filter((d) => d.equipmentID === "" && d.asset === selectedAsset.name);
      const assetFiles = [];
      const assetNames = [];
      const assetLoading = [];
      for (let index = 0; index < selectedDocs.length; index++) {
        assetFiles.push(null);
        assetNames.push(selectedDocs[index].name);
        assetLoading.push(false);
      }
      setAssetDocNames(assetNames);
      setAssetDocFiles(assetFiles);
      setAssetDocs(selectedDocs);
      setAssetDocLoading(assetLoading);
      if (!isNil(selectedDoc) && selectedDoc.asset !== selectedAsset.name) {
        setPdfUrl("");
      }
    }
    if (isEmpty(documents)) {
      setAssetDocNames([]);
      setAssetDocFiles([]);
      setAssetDocs([]);
      setPdfUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, selectedAsset]);

  useEffect(() => {
    if (!isEmpty(documents)) {
      const selectedDocs = documents.filter(
        (d) => d.equipmentID === selectedEquipmentID && d.asset === selectedAsset.name,
      );
      const equipmentFiles = [];
      const equipmentNames = [];
      const equipmentLoading = [];
      for (let index = 0; index < selectedDocs.length; index++) {
        equipmentFiles.push(null);
        equipmentNames.push(selectedDocs[index].name);
        equipmentLoading.push(false);
      }
      setEquipmentDocNames(equipmentNames);
      setEquipmentDocFiles(equipmentFiles);
      setEquipmentDocs(selectedDocs);
      setEquipmentDocLoading(equipmentLoading);
      if (!isNil(selectedDoc) && selectedDoc.equipmentID !== selectedEquipmentID && selectedDoc.equipmentID !== "") {
        setPdfUrl("");
      }
    }
    if (isEmpty(documents)) {
      setEquipmentDocNames([]);
      setEquipmentDocFiles([]);
      setEquipmentDocs([]);
      setPdfUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, selectedEquipment]);

  useEffect(() => {
    getFile(assetDocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDocs]);

  useEffect(() => {
    if (pdfUrl !== "") {
      getFile(equipmentDocs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDocs]);

  async function getFile(docs) {
    let document = selectedDoc;
    const aux = docs[docs.length - 1];
    if (!isEmpty(docs)) {
      if (!arrayIncludesObject(docs, selectedDoc)) {
        document = docs[0];
      }
    }

    if (!isNil(document)) {
      if (!isNil(aux) && !isNil(aux.id)) {
        const response = await getDocumentsFile(document);
        if (!isNil(response) && document.filename?.endsWith(".pdf")) {
          if (response.status === 200) {
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
            setSelectedDoc(document);
          }
        }
      }
    } else {
      setPdfUrl("");
    }
  }

  const handleFileChange = async (event, index, files, setFiles, docs, setDocs) => {
    const file = event.target.files[0];
    let newDocName = clone(docs);
    newDocName[index].filename = file.name;
    setDocs(newDocName);
    if (file) {
      let newFiles = clone(files);
      newFiles[index] = file;
      setFiles(newFiles);
    }
  };

  function areObjectsEqual(obj1, obj2) {
    if (!isNil(obj1) && !isNil(obj2)) {
      return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
    }
  }

  function obtainExtension(file) {
    const parts = file.split(".");
    return parts.length > 1 ? parts.pop().toLowerCase() : "";
  }

  function fileType(extension) {
    for (const type in fileTypes) {
      if (fileTypes[type].includes(extension)) {
        return type;
      }
    }
    return null;
  }

  const IconDisplay = ({ doc, docIndex }) => {
    const file = doc.filename;
    const extension = obtainExtension(file);
    const type = fileType(extension);

    if (type === "image") {
      return (
        <PhotoIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
    if (type === "video") {
      return (
        <OndemandVideoIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
    if (type === "sound") {
      return (
        <MusicNoteIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
    if (type === "compressed") {
      return (
        <FolderZipIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
    if (doc.filename?.endsWith(".pdf")) {
      return (
        <PictureAsPdfIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
    if (isNil(type)) {
      return (
        <InsertDriveFileIcon
          id={`display_${docIndex}_${type}`}
          sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
        />
      );
    }
  };

  function arrayIncludesObject(array, obj) {
    return array.some((item) => areObjectsEqual(item, obj));
  }
  if (assets.loading || isNil(currentUser)) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  const DocumentManagement = ({ type, docs, setDocs, docFiles, setDocFiles, loading, setLoading }) => {
    const names = type === "equipments" ? equipmentDocNames : assetDocNames;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          alignItems: "flex-start",
          margin: "0 0.7rem",
        }}
      >
        <div style={{ fontSize: "larger", minWidth: "10rem", margin: "1rem" }}>
          {intl.formatMessage({ id: `${type}.documents` })}:
        </div>
        <div style={{ marginLeft: "0.1%" }}>
          {docs.map((doc, docIndex) => (
            <Box key={`box_${docIndex}_${type}`} display="flex" alignItems="center">
              <IconDisplay doc={doc} docIndex={docIndex} />
              <TextField
                key={`key_-docname_${type}${docIndex}`}
                defaultValue={names[docIndex]}
                variant="outlined"
                autoComplete="off"
                margin="dense"
                id={`docname_${type}${docIndex}`}
                label={intl.formatMessage({ id: "documentation.name" })}
                type="text"
                required
                disabled={loading[docIndex] || !isNil(doc.id)}
                onBlur={(e) => {
                  let newDocName = clone(names);
                  newDocName[docIndex] = e.target.value;
                  if (type === "equipments") {
                    setEquipmentDocNames(newDocName);
                  } else {
                    setAssetDocNames(newDocName);
                  }
                }}
                style={{ marginRight: 8, flexGrow: 1, width: "35%" }}
                size="medium"
              />
              <input
                type="file"
                //accept="application/pdf"
                style={{ display: "none" }}
                id={`pdf-input${docIndex}_${type}`}
                onChange={(e) => {
                  handleFileChange(e, docIndex, docFiles, setDocFiles, docs, setDocs);
                }}
                key={`inputdoc${docIndex}`}
              />
              {isNil(doc.id) ? (
                <label htmlFor={`pdf-input${docIndex}_${type}`}>
                  <Button
                    variant="contained"
                    key={`buttondoc_${type}_${docIndex}`}
                    component="span"
                    size="medium"
                    disabled={loading[docIndex]}
                    style={{
                      height: 40,
                      backgroundColor: loading[docIndex] ? palette.light : palette.primary,
                    }}
                  >
                    {intl.formatMessage({ id: "add.documentation" })}
                  </Button>
                </label>
              ) : null}
              {!isNil(doc.id) && doc.filename?.endsWith(".pdf") ? (
                <VisibilityIcon
                  id={`see_${docIndex}_${type}`}
                  onClick={async () => {
                    const document = doc;
                    const response = await getDocumentsFile(document);
                    if (response.data.type === "application/pdf") {
                      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                      const pdfUrl = URL.createObjectURL(pdfBlob);
                      setSelectedDoc(document);
                      setTimeout(() => {
                        setPdfUrl(pdfUrl);
                      }, 0);
                    }
                  }}
                  sx={{ color: palette.primary, cursor: "pointer", marginLeft: "1%" }}
                />
              ) : null}

              {!loading[docIndex] && isNil(doc.id) ? (
                <FileUploadIcon
                  id={`edit_${docIndex}_${type}`}
                  onClick={async () => {
                    let auxLoading = clone(loading);
                    auxLoading[docIndex] = true;
                    setLoading(auxLoading);
                    setTimeout(async () => {
                      const result = await postDocuments(doc, names[docIndex], docFiles[docIndex], intl);
                      if (result.status === 200) {
                        dispatch(getDocumentsDataAction());
                        auxLoading[docIndex] = false;
                        setLoading(auxLoading);
                      }
                    }, 0);
                  }}
                  sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
                />
              ) : loading[docIndex] ? (
                <CircularProgress size={30} sx={{ color: palette.primary, margin: "1%" }} />
              ) : null}

              {!isNil(doc.id) && !loading[docIndex] ? (
                <FileDownloadIcon
                  id={`download_${docIndex}_${type}`}
                  onClick={async () => {
                    const a = document.createElement("a");
                    if (doc.filename?.endsWith(".pdf")) {
                      a.href = pdfUrl;
                    } else {
                      const response = await getDocumentsFile(doc);
                      const fileBlob = new Blob([response.data]);
                      const fileUrl = URL.createObjectURL(fileBlob);
                      a.href = fileUrl;
                    }
                    a.download = doc.filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }}
                  sx={{ color: palette.primary, cursor: "pointer", margin: "1%" }}
                />
              ) : null}

              {!loading[docIndex] ? (
                <DeleteForeverIcon
                  id={`delete_${docIndex}_${type}`}
                  onClick={async () => {
                    if (!isNil(doc.id)) {
                      const result = await deleteDocument(doc, intl);
                      if (result.status === 200) {
                        setTimeout(() => {
                          dispatch(getDocumentsDataAction());
                        }, 0);
                      }
                    } else {
                      let newDocs = clone(docs);
                      let newDocFiles = clone(docFiles);
                      newDocs.splice(docIndex, 1);
                      newDocFiles.splice(docIndex, 1);
                      setDocs(newDocs);
                      setDocFiles(newDocFiles);
                    }
                  }}
                  sx={{ color: palette.primary, cursor: "pointer" }}
                />
              ) : null}
            </Box>
          ))}
          <IconButton
            style={{ backgroundColor: palette.dividerLight }}
            key={`add_${type}_doc`}
            onClick={() => {
              let newDocName = clone(docs);
              newDocName.push({
                filename: "",
                asset: selectedAsset.name,
                equipmentID: type !== "equipments" ? "" : selectedEquipmentID,
                name: `document${newDocName.length}`,
              });
              let newFiles = clone(docFiles);
              newFiles.push(false);
              setDocs(newDocName);
              setDocFiles(newFiles);
            }}
          >
            <AddIcon sx={{ color: palette.primary }} />
          </IconButton>
        </div>
      </div>
    );
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div
        style={{
          margin: "1%",
          backgroundColor: palette.likeWhite,
          padding: "0.2rem 0.4rem 0.2rem 0",
          overflowY: "scroll",
          height: "90vh",
          overflowX: "hidden",
        }}
      >
        <TextField
          id="asset_to_configure"
          margin="dense"
          style={{ width: "10rem" }}
          variant="outlined"
          defaultValue={assets?.data?.[0]?.name}
          onChange={(e) => {
            const selectedAssetName = e.target.value;
            const selectedAsset = assets.data.find((asset) => asset.name === selectedAssetName);
            setSelectedAsset(selectedAsset);
            setEquipments(selectedAsset.equipments);
            setSelectedEquipment(selectedAsset.equipments[0]);
            setSelectedEquipmentID(selectedAsset.equipments[0].equipmentID);
            setSelectedDoc(null);
          }}
          select
          label={intl.formatMessage({ id: "assets" })}
          required
        >
          {assets.data.map((asset, index) => (
            <MenuItem value={asset.name} key={asset.name + index}>
              {asset.name}
            </MenuItem>
          ))}
        </TextField>
        <DocumentManagement
          key={"asset_doc_manage"}
          type={"asset"}
          docs={assetDocs}
          setDocs={setAssetDocs}
          docFiles={assetDocFiles}
          setDocFiles={setAssetDocFiles}
          loading={assetDocLoading}
          setLoading={setAssetDocLoading}
        />
        <div
          style={{
            backgroundColor: palette.likeWhite,
            padding: "0.2rem 0.4rem 0.2rem 0",
          }}
        >
          {!isNil(equipments) && !isEmpty(equipments) ? (
            <TextField
              id="equipment_to_configure"
              margin="dense"
              style={{ width: "10rem" }}
              variant="outlined"
              defaultValue={selectedEquipmentID}
              value={selectedEquipmentID}
              onChange={(e) => {
                const selectedEquipmentIDOnChange = e.target.value;
                const selectedEquipmentChange = equipments.find(
                  (equip) => equip.equipmentID === selectedEquipmentIDOnChange,
                );
                setSelectedEquipment(selectedEquipmentChange);
                setSelectedEquipmentID(selectedEquipmentIDOnChange);
                const selection = selectedDoc.equipmentID === "" ? selectedDoc : null;
                setSelectedDoc(selection);
              }}
              select
              label={intl.formatMessage({ id: "equipment" })}
              required
            >
              {equipments.map((equipment, index) => (
                <MenuItem value={equipment.equipmentID} key={equipment.equipmentID + index}>
                  {equipment.equipmentID}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </div>
        <DocumentManagement
          key={"equipment_doc_manage"}
          type={"equipments"}
          docs={equipmentDocs}
          setDocs={setEquipmentDocs}
          docFiles={equipmentDocFiles}
          setDocFiles={setEquipmentDocFiles}
          loading={equipmentDocLoading}
          setLoading={setEquipmentDocLoading}
        />
      </div>

      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "50rem",
          width: "75%",
        }}
      >
        {pdfUrl !== "" ? (
          <Worker workerUrl={pdfjsWorker}>
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]}></Viewer>
          </Worker>
        ) : null}
      </div>
    </div>
  );
};

export default Documentation;
