import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 18rem;
`;

export const ImageContent = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
`;

export const NameContent = styled.div`
  font-size: x-large;
  margin: 0.3rem;
`;

export const TableContent = styled.div`
  width: 100%;
`;

export const TableRowContent = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: 600;
  padding: 0.2rem;
  font-size: 14px;
`;
