import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "ramda";
import { getPoints } from "../api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const assetsPointsSlice = createSlice({
  name: "ASSET_VALUES",
  initialState,
  reducers: {
    getAssetsPointsRequest: (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    },
    getAssetsPointsSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAssetsPointsFailure: (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
    setAssetsPointsData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { getAssetsPointsRequest, getAssetsPointsSuccess, getAssetsPointsFailure, setAssetsPointsData } =
  assetsPointsSlice.actions;

export const getAssetsPointsAction = (assets) => {
  return async (dispatch) => {
    try {
      dispatch(getAssetsPointsRequest());
      let response = await getPoints();
      if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
        const points = response.data.data ?? [];
        for (let i = 0; i < points.length; i++) {
          points[i].sort((a, b) => a.idFoPoss - b.idFoPoss);
        }

        let payload = {};
        for (let i = 0; i < assets.length; i++) {
          const asset = assets[i];
          let assetPoints = [];
          for (let j = 0; j < points.length; j++) {
            if (points[j]?.[0]?.asset === asset.name) {
              assetPoints = points[j];
              break;
            }
          }
          payload[asset.name] = assetPoints.filter((e) => e.asset === asset.name) ?? [];
        }
        dispatch(getAssetsPointsSuccess(payload));
        return;
      }
      dispatch(getAssetsPointsFailure("Invalid response"));
    } catch (error) {
      dispatch(getAssetsPointsFailure(error));
    }
  };
};

export const clearAssetsPointsAction = () => {
  return async (dispatch) => {
    dispatch(getAssetsPointsSuccess(null));
  };
};

export default assetsPointsSlice.reducer;
