import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./App/store";
import IntlProviderWrapper from "./code/components/IntlProviderWrapper";
import "./index.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <App />
    </IntlProviderWrapper>
  </Provider>,
);

reportWebVitals();
