import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import uptech from "../../../images/Uptech.png";
import bg from "../../../images/bg.jpg";

import { setAuthAction } from "../../redux/authSlice";
import { clearAssetsDataAction } from "../../redux/assetsSlice";
import { clearConfigDataAction } from "../../redux/configSlice";
import { clearUserDataAction } from "../../redux/userSlice";
import LoginForm from "./components/LoginForm";
import { loginUser } from "./utils/utils";
import {
  Content,
  CopyrightContent,
  FooterContent,
  FormContent,
  LeftContent,
  LeftTextBox,
  LogoContent,
  RightContent,
  UptechLogoImg,
} from "./styled";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(clearAssetsDataAction());
    dispatch(clearConfigDataAction());
    dispatch(clearUserDataAction());
    dispatch(setAuthAction(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content image={bg}>
      <LeftContent>
        <LeftTextBox>{intl.formatMessage({ id: "login.left.message" })}</LeftTextBox>
      </LeftContent>
      <RightContent>
        <LogoContent></LogoContent>
        <FormContent>
          <UptechLogoImg alt="UPTECH" src={uptech} />
          <LoginForm
            handleLogin={(username, password, remember) =>
              loginUser({ username, password, remember, setLoading, navigate, dispatch, intl })
            }
            loading={loading}
            testId="login"
          />
        </FormContent>
        <FooterContent>
          <CopyrightContent>{`Copyright © 2024 UPTECH. ${intl.formatMessage({ id: "copyright" })}.`}</CopyrightContent>
        </FooterContent>
      </RightContent>
      <ToastContainer limit={4} position="top-right" autoClose={5000} theme="dark" />
    </Content>
  );
};

export default Login;
