import React, { useEffect } from "react";
import * as echarts from "echarts";

const WaterfallChart = ({ prefix, data, boundaries, colorRange }) => {
  useEffect(() => {
    const chartElement = document.getElementById(`${prefix}-waterfall-chart`);
    if (chartElement) {
      const myChart = echarts?.init(chartElement);

      const options = {
        tooltip: {
          position: "top",
        },
        grid: {
          height: "50%",
          top: "10%",
        },
        visualMap: {
          min: boundaries?.[0],
          max: boundaries?.[1],
          calculable: true,
          orient: "horizontal",
          left: "center",
          bottom: "10%",
          inRange: {
            color: colorRange,
          },
        },
        xAxis: {
          type: "category",
          data: data?.xAxis?.data,
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: data?.yAxis?.data,
          splitArea: {
            show: true,
          },
        },
        series: data?.series?.map((s) => ({
          ...s,
          type: "heatmap",
          emphasis: {
            itemStyle: {
              shadowBlur: boundaries?.[1],
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        })),
      };

      myChart?.setOption(options, true);

      return () => {
        myChart?.dispose();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, prefix, boundaries]);

  return <div id={`${prefix}-waterfall-chart`} style={{ width: "100%", height: "300px" }} />;
};

export default WaterfallChart;
