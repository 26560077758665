import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
`;

export const HeaderLogo = styled.img`
  height: 70%;
  margin-left: 0.5rem;
`;
