import { isEmpty, isNil } from "ramda";
import {
  getAnalysisSpatialData,
  getAssetPointsSerialData,
  getAssetsDatetimes,
  getBooleanAnalysisSpatialData,
} from "../../api";
import { toast } from "react-toastify";
import { BOOLEAN_MEASURES } from "../../utils/consts";

export const loadData = async ({
  asset,
  selectedEquipment,
  dateTimeList,
  points,
  data,
  equipment_ID,
  channel,
  setData,
  setBooleanData,
  setLoading,
  setPercentiles,
  setDifferences,
}) => {
  let noData = false;
  setLoading(true);
  try {
    const result = [];
    const resultBoolean = [];
    for (let i = 0; i < dateTimeList.length; i++) {
      const date = dateTimeList[i];

      const spatialDataResponse = await getAnalysisSpatialData(asset.name, date, equipment_ID, channel);
      if (
        !isNil(spatialDataResponse?.status) &&
        spatialDataResponse.status === 200 &&
        !isNil(spatialDataResponse.data)
      ) {
        const spatialData = spatialDataResponse.data.data;
        if (isEmpty(spatialData)) {
          noData = true;
          setLoading(false);
        } else {
          setPercentiles(spatialData.boundaries);
          setDifferences(spatialData.differences);
          const newItem = {};
          const newItemBoolean = {};
          newItem["date"] = date;
          newItem["pk"] = points?.points.map((p) => p.pk);
          newItemBoolean["date"] = date;
          newItemBoolean["pk"] = points?.points.map((p) => p.pk);
          for (let j = 0; j < selectedEquipment.pMeasureNames.length; j++) {
            const measureName = selectedEquipment.pMeasureNames[j];
            const values = BOOLEAN_MEASURES.includes(measureName)
              ? spatialData.additional_measures[measureName]
              : spatialData[measureName];
            if (BOOLEAN_MEASURES.includes(measureName)) {
              newItemBoolean[measureName] = {
                datetime: date,
                values: values,
              };
            } else {
              newItem[measureName] = {
                datetime: date,
                values: values,
              };
            }
          }
          result.push(newItem);
          resultBoolean.push(newItemBoolean);
        }
      }
    }
    await setData(result);
    await setBooleanData(resultBoolean);
    if (!noData) setLoading(false);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
};

export const loadBooleanData = async ({ asset, dateTimeList, points, booleanData, setBooleanData, setLoading }) => {
  setLoading(true);
  try {
    const result = [];
    for (let i = 0; i < dateTimeList.length; i++) {
      const date = dateTimeList[i];
      const alreadyData = booleanData?.find((d) => d.date === date);
      if (isNil(alreadyData)) {
        const spatialDataResponse = await getBooleanAnalysisSpatialData(asset.name, date);
        if (
          !isNil(spatialDataResponse?.status) &&
          spatialDataResponse.status === 200 &&
          !isNil(spatialDataResponse.data)
        ) {
          const spatialData = spatialDataResponse.data.data.find((sp) => sp.asset === asset.name);
          const newItem = {};
          newItem["date"] = date;
          newItem["pk"] = points?.map((p) => p.pk);
          for (let j = 0; j < spatialData.pMeasureNames.length; j++) {
            const measureName = spatialData.pMeasureNames[j];
            newItem[measureName] = {
              datetime: spatialData?.datetime,
              values: spatialData?.pMeasureValues[j],
            };
          }
          result.push(newItem);
        }
      } else {
        result.push(alreadyData);
      }
    }
    await setBooleanData(result);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};

export const loadDateOptions = async ({
  date,
  time,
  asset,
  setDateOptions = () => {},
  setTimeOptions = () => {},
  setDateOptionsModal = () => {},
  setTimeOptionsModal = () => {},
  setDate = () => {},
  setTime = () => {},
  setLoading = () => {},
}) => {
  setLoading(true);
  const response = await getAssetsDatetimes();
  if (!isNil(response?.status) && response.status === 200 && !isNil(response.data) && !isEmpty(response.data.data)) {
    const data = response.data.data;
    let dates = new Set();
    let times = new Set();

    data.sort((a, b) => {
      const dA = new Date(a.dateTime);
      const dB = new Date(b.dateTime);
      return dB - dA;
    });

    let localDateIndex = null;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.asset === asset) {
        const temp = item.dateTime.indexOf("T") !== -1 ? item.dateTime.split("T") : item.dateTime.split(" ");
        dates.add(temp[0]);
        if (isNil(date)) {
          date = temp[0];
        }
        if (temp[0] === date) {
          localDateIndex = isNil(localDateIndex) ? Array.from(dates).indexOf(date) : localDateIndex;
          times.add(temp[1]);
        }
      }
    }
    dates = Array.from(dates);
    times = Array.from(times);
    const localTimeIndex = isNil(time) ? 0 : times.indexOf(time);
    setDate(localDateIndex);
    setTime(localTimeIndex);
    setDateOptions(dates);
    setTimeOptions(times);
    setDateOptionsModal(dates);
    setTimeOptionsModal(times);
    setLoading(false);
  } else {
    setDateOptions(null);
    setTimeOptions(null);
    setDateOptionsModal(null);
    setTimeOptionsModal(null);
    setDate(null);
    setTime(null);
    setLoading(false);
  }
};

export const loadDateOptionsChannel = async ({
  date,
  time,
  asset,
  equipment,
  channel,
  dateTime,
  setDateOptions = () => {},
  setTimeOptions = () => {},
  setDateOptionsModal = () => {},
  setTimeOptionsModal = () => {},
  setDate = () => {},
  setTime = () => {},
  setLoading = () => {},
  setDateTimeList = () => {},
}) => {
  const response = await getAssetsDatetimes([asset]);
  if (!isNil(response?.status) && response.status === 200 && !isNil(response.data) && !isEmpty(response.data.data)) {
    const data = response.data.data;
    let dates = new Set();
    let times = new Set();

    let dateTimeParam = null;
    let dateParam = null;
    let timeParam = null;
    if (!isNil(dateTime)) {
      dateTimeParam = dateTime.split("T");
      dateParam = dateTimeParam[0];
      timeParam = dateTimeParam[1];
    }
    data.sort((a, b) => {
      const dA = new Date(a.dateTime);
      const dB = new Date(b.dateTime);
      return dB - dA;
    });

    let localDateIndex = null;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.asset === asset && item.equipment_ID === equipment && item.channel === channel) {
        const temp = item.dateTime.indexOf("T") !== -1 ? item.dateTime.split("T") : item.dateTime.split(" ");
        dates.add(temp[0]);
        if (isNil(date)) {
          if (isNil(dateParam)) {
            date = temp[0];
          } else {
            date = dateParam;
          }
        }
        if (temp[0] === date) {
          localDateIndex = isNil(localDateIndex) ? Array.from(dates).indexOf(date) : localDateIndex;
          times.add(temp[1]);
        }
      }
    }
    dates = Array.from(dates);
    times = Array.from(times);

    let localTimeIndex = isNil(time) ? 0 : times.indexOf(time);
    if (!isNil(dateTime)) {
      localDateIndex = dates.indexOf(dateParam);
      localTimeIndex = times.indexOf(timeParam);
    }
    setDate(localDateIndex);
    setTime(localTimeIndex);
    setDateOptions(dates);
    setTimeOptions(times);
    setDateOptionsModal(dates);
    setTimeOptionsModal(times);
    setLoading(false);
  } else {
    setDateOptions(null);
    setTimeOptions(null);
    setDateOptionsModal(null);
    setTimeOptionsModal(null);
    setDate(null);
    setTime(null);
    setDateTimeList([]);
    setLoading(true);
  }
};

export const handleModalAccept = ({ date, time, dateTimeList, setDateTimeList }) => {
  const dateStr = `${date}T${time}`;
  if (!dateTimeList.includes(dateStr)) {
    setDateTimeList([...dateTimeList, dateStr]);
  }
};

export const initialSpatialSelectHandler = ({
  data,
  start,
  end,
  measureName,
  setTemporalDataList,
  minsWaterfall,
  setMinsWaterfall,
  maxsWaterfall,
  setMaxsWaterfall,
  currentAsset,
  spatialDataList,
  setLoading,
  dateTime,
  equipment,
  selectedEquipment,
  channel,
}) => {
  setLoading(true);
  let i = start;
  let j = end;
  if (j - i >= 64) {
    let centerPoint = parseInt((j - i) / 2) + i;
    i = centerPoint >= 32 ? centerPoint - 32 : 0;
    centerPoint += 32;
    const count = data?.series?.[0]?.data?.length ?? 0;
    j = centerPoint < count ? centerPoint : count - 1;
  }
  //initialLoadTemporalData({
  //  asset: currentAsset,
  //  startIndex: i,
  //  endIndex: j,
  //  pkList: spatialDataList?.[0]?.pk?.slice(i, j + 1),
  //  measureName,
  //  setTemporalDataList,
  //  setMinsWaterfall,
  //  setMaxsWaterfall,
  //  minsWaterfall,
  //  maxsWaterfall,
  //  setLoading,
  //  dateTime,
  //  selectedEquipment,
  //  equipment,
  //  channel,
  //});
};

export const initialLoadTemporalData = async ({
  asset,
  startIndex,
  endIndex,
  pkList,
  measureName,
  setTemporalDataList,
  setMinsWaterfall,
  setMaxsWaterfall,
  setLoading,
  dateTime,
  selectedEquipment,
  equipment,
  channel,
}) => {
  try {
    const result = {};
    const minsWaterfall = {};
    const maxsWaterfall = {};

    const measures = selectedEquipment.pMeasureNames.filter((m) => !BOOLEAN_MEASURES.includes(m));
    for (let mI = 0; mI < measures.length; mI++) {
      const measureName = measures[mI];
      const response = await getAssetPointsSerialData(
        asset.name,
        startIndex,
        endIndex,
        dateTime,
        [measureName],
        equipment,
        channel,
        30,
        5000,
      );
      if (!isNil(response?.status) && response.status === 200 && !isNil(response.data)) {
        const data = response.data?.data;

        let minValue = Infinity;
        let maxValue = Number.NEGATIVE_INFINITY;
        if (isNil(result[measureName])) {
          result[measureName] = [];
        }
        for (let i = 0, j = startIndex; i < data.data.length; i++, j++) {
          const item = data.data[i];
          const values = item[measureName].map((item) => item.value);
          const min = Math.min(...values);
          const max = Math.max(...values);
          if (minValue > min) minValue = min;
          if (maxValue < max) maxValue = max;

          result[measureName].push({
            values: item[measureName].map((v) => v.value),
            datetimes: item[measureName].map((v) => v.datetime),
            pk: pkList[i],
          });
        }
        if (Math.abs(minValue) > maxValue && measureName === "strain") {
          maxValue = Math.abs(minValue);
        }
        minsWaterfall[measureName] = minValue;
        maxsWaterfall[measureName] = maxValue;
      }
    }

    setMinsWaterfall(minsWaterfall);
    setMaxsWaterfall(maxsWaterfall);
    setTemporalDataList(result);
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};
